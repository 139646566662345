import React, { useState } from 'react';
import { fetchApi, endpoints } from '../../utils/api';
import PostSubmit from './post-submit';
import LoadingModal from '../bonus/api-loading-modal';
import { CHAR_LIMITS, getRemainingChars } from '../../utils/constants';

function Scout() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        linkedin: '',
        university: '',
        whyScout: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsOpen(true);
        
        try {
            const scoutAppData = {
                university: formData.university,
                why_scout: formData.whyScout,
                submitted_at: new Date().toLocaleString()
            };

            await fetchApi(endpoints.submitScout(), {
                method: 'POST',
                body: JSON.stringify({
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    email: formData.email,
                    linkedin: formData.linkedin,
                    source: 'scout_application',
                    scout_app: scoutAppData
                }),
            });

            setIsSubmitted(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            setError({
                message: error.message,
                source: 'scout',
                time: new Date().toISOString()
            });
        } finally {
            setIsOpen(false);
        }
    };

    if (isSubmitted) {
        return <PostSubmit />;
    }

    return (
        <main className="container">
            <LoadingModal isOpen={isOpen} message="1 Sec. The Server is Cooking..." />
            <div className="scout-container">
                <h1 className="page-title">LEAPYEAR SCOUT</h1>
                <p className="info-text">The world needs more builders.</p>
                <p className="info-text">Our campus leads help find them.</p>
                <p className="info-text">If you are interested, let us know.</p>

                <form className="scout-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="firstName" className="info-text">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="firstName"
                            className="form-input"
                            required
                            placeholder="..."
                            value={formData.firstName}
                            onChange={handleChange}
                            maxLength={CHAR_LIMITS.SCOUT_FIRST_NAME}
                        />
                        <div className={getRemainingChars(formData.firstName, CHAR_LIMITS.SCOUT_FIRST_NAME).className}>
                            {getRemainingChars(formData.firstName, CHAR_LIMITS.SCOUT_FIRST_NAME).remaining} characters remaining
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName" className="info-text">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="lastName"
                            className="form-input"
                            required
                            placeholder="..."
                            value={formData.lastName}
                            onChange={handleChange}
                            maxLength={CHAR_LIMITS.SCOUT_LAST_NAME}
                        />
                        <div className={getRemainingChars(formData.lastName, CHAR_LIMITS.SCOUT_LAST_NAME).className}>
                            {getRemainingChars(formData.lastName, CHAR_LIMITS.SCOUT_LAST_NAME).remaining} characters remaining
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" className="info-text">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            className="form-input"
                            required
                            placeholder="so we can contact you"
                            value={formData.email}
                            onChange={handleChange}
                            maxLength={CHAR_LIMITS.SCOUT_EMAIL}
                        />
                        <div className={getRemainingChars(formData.email, CHAR_LIMITS.SCOUT_EMAIL).className}>
                            {getRemainingChars(formData.email, CHAR_LIMITS.SCOUT_EMAIL).remaining} characters remaining
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="university" className="info-text">
                            University
                        </label>
                        <input
                            type="text"
                            id="university"
                            className="form-input"
                            required
                            placeholder="where do you want to scout?"
                            value={formData.university}
                            onChange={handleChange}
                            maxLength={CHAR_LIMITS.SCOUT_UNIVERSITY}
                        />
                        <div className={getRemainingChars(formData.university, CHAR_LIMITS.SCOUT_UNIVERSITY).className}>
                            {getRemainingChars(formData.university, CHAR_LIMITS.SCOUT_UNIVERSITY).remaining} characters remaining
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="linkedin" className="info-text">
                            LinkedIn Link
                        </label>
                        <input
                            type="text"
                            id="linkedin"
                            className="form-input"
                            required
                            placeholder="hoping to verify you are human :)"
                            value={formData.linkedin}
                            onChange={handleChange}
                            maxLength={CHAR_LIMITS.SCOUT_LINKEDIN}
                        />
                        <div className={getRemainingChars(formData.linkedin, CHAR_LIMITS.SCOUT_LINKEDIN).className}>
                            {getRemainingChars(formData.linkedin, CHAR_LIMITS.SCOUT_LINKEDIN).remaining} characters remaining
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="whyScout" className="info-text">
                            Why Would You Be a Good Scout?
                        </label>
                        <textarea
                            id="whyScout"
                            className="form-input"
                            required
                            placeholder="tell us your story"
                            value={formData.whyScout}
                            onChange={handleChange}
                            maxLength={CHAR_LIMITS.SCOUT_WHY}
                        />
                        <div className={getRemainingChars(formData.whyScout, CHAR_LIMITS.SCOUT_WHY).className}>
                            {getRemainingChars(formData.whyScout, CHAR_LIMITS.SCOUT_WHY).remaining} characters remaining
                        </div>
                    </div>
                    {error && (
                        <div className="error-submit-container">
                            <h1 className="page-title">Ah Sh*t...</h1>
                            <p className="info-text">I'm sorry. We probably wrote some bad code.</p>
                            <p className="info-text">If you got this. Feel free to copy your info and email us at build@takeleapyear.com.</p>
                            <p className="info-text">We will review & get back to you soon.</p>
                            <p className="info-text">Sincerely, the LeapYear Team</p>
                            <button 
                                type="button"
                                className="try-again-btn"
                                onClick={() => setError(null)}
                            >
                                Dismiss Error
                            </button>
                        </div>
                    )}
                    <button type="submit" className="submit-button">
                        Submit
                    </button>
                </form>
            </div>
        </main>
    );
}

export default Scout; 