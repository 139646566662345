// Character limits for application form fields
export const CHAR_LIMITS = {
  PROJECT_NAME: 100,
  PROBLEM: 2000,
  SOLUTION: 2000,
  TARGET_CUSTOMER: 2000,
  WHY_EQUIPPED: 2000,
  UNIQUE_INSIGHT: 2000,
  WHY_DO_THIS: 2000,
  RELEVANT_LINKS: 1000,
  HOW_HEARD: 1000,
  ADDITIONAL_INFO: 2000,
  // Founder fields
  FOUNDER_NAME: 50,
  FOUNDER_LINKS: 500,
  FOUNDER_EMAIL: 100,
  // Scout fields
  SCOUT_FIRST_NAME: 50,
  SCOUT_LAST_NAME: 50,
  SCOUT_EMAIL: 100,
  SCOUT_LINKEDIN: 500,
  SCOUT_UNIVERSITY: 100,
  SCOUT_WHY: 2000
};

// Helper function to get remaining characters and status
export const getRemainingChars = (text, limit) => {
  const remaining = limit - (text?.length || 0);
  let className = 'char-counter';
  
  if (remaining <= Math.min(20, limit * 0.1)) {
    className += ' at-limit';
  } else if (remaining <= Math.min(50, limit * 0.2)) {
    className += ' near-limit';
  }
  
  return {
    remaining,
    className
  };
};