import React, { useState, useEffect } from 'react';

// Global cache for investments data
let investmentsCache = {
  data: null,
  timestamp: null,
  promise: null
};

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Function to check if cache is valid
const isCacheValid = () => {
  return (
    investmentsCache.data !== null &&
    investmentsCache.timestamp !== null &&
    Date.now() - investmentsCache.timestamp < CACHE_DURATION
  );
};

// Function to fetch investments data
const fetchInvestments = async (force = false) => {
  // If we have a valid cache and don't force refresh, return cached data
  if (!force && isCacheValid()) {
    return investmentsCache.data;
  }

  // If there's already a request in flight, return its promise
  if (investmentsCache.promise) {
    return investmentsCache.promise;
  }

  // Create new request promise
  investmentsCache.promise = fetch('/api/public/website-inputs?investments=true', {
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    }
  })
    .then(response => response.json())
    .then(investmentsData => {
      // Process investments data
      const groupedInvestments = (investmentsData.data || []).reduce((acc, investment) => {
        const year = investment.year || 'Unknown';
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(investment);
        return acc;
      }, {});

      // Sort years and investments
      const sortedYears = Object.keys(groupedInvestments).sort((a, b) => {
        const yearA = parseInt(a) || 0;
        const yearB = parseInt(b) || 0;
        return yearA - yearB;
      });

      sortedYears.forEach(year => {
        groupedInvestments[year].sort((a, b) => {
          // First check for "Stealth" tagline - push these to the bottom
          if (a.company_name === "Stealth" && b.company_name !== "Stealth") {
            return 1;  // Changed from -1 to 1 to push to bottom
          }
          if (b.company_name === "Stealth" && a.company_name !== "Stealth") {
            return -1;  // Changed from -1 to -1 (correct)
          }
          
          // Then apply the original sorting logic for non-stealth companies
          if ((!a.company_tagline && !b.company_tagline) || (a.company_tagline && b.company_tagline)) {
            return 0;
          }
          if (a.company_tagline && !b.company_tagline) {
            return -1;
          }
          return 1;
        });
      });

      // Update cache
      investmentsCache.data = groupedInvestments;
      investmentsCache.timestamp = Date.now();
      return groupedInvestments;
    })
    .finally(() => {
      investmentsCache.promise = null;
    });

  return investmentsCache.promise;
};

function Investments({ showTitle = true }) {
  const [investments, setInvestments] = useState(investmentsCache.data);
  const [loading, setLoading] = useState(!isCacheValid());
  const [error, setError] = useState(null);

  useEffect(() => {
    let mounted = true;

    const loadData = async () => {
      if (!isCacheValid()) {
        setLoading(true);
        try {
          const data = await fetchInvestments();
          if (mounted) {
            setInvestments(data);
          }
        } catch (err) {
          console.error('Error fetching data:', err);
          if (mounted) {
            setError('Failed to fetch data');
          }
        } finally {
          if (mounted) {
            setLoading(false);
          }
        }
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  if (loading) return <div className="empty-loading-container"></div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <main className="container">
      {showTitle && (
        <>
          <h1 className="page-title">INVESTMENTS</h1>
          <p>We fund 25-30 teams/year.</p>
          <p>A mix of students, dropouts, & grads.</p>
          <p>We back builders with a unique perspective - regardless of industry.</p>
          <p>To date, we've invested in software, hardware, robotics, medtech + CPG.</p>
        </>
      )}
      {Object.entries(investments || {})
      .reverse()
      .map(([year, yearInvestments]) => (
        <div key={year} className="year-section">
          <h2 className="year-title">{year}</h2>
          <div className="investments-grid">
              {yearInvestments.map((item, index) => (
                <div key={index} className="investments-grid-item">
                  <div className="text-container">
                    <h4 className="investments-school">{item.college}</h4>
                    <h3 className="investments-name">{item.company_name}</h3>
                    <p className="investments-title">{item.company_tagline}</p>
                  </div>
                </div>
              ))}
            </div>
        </div>
      ))}
    </main>
  );
}

export default Investments;