import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { endpoints, fetchApi, getApiUrl } from '../../utils/api';
import { useNavigate } from 'react-router-dom';

function MainFooter() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const handleHomeClick = () => {
    window.scrollTo(0, 0);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (!email) return;

    setIsSubmitting(true);
    setStatus({ type: '', message: '' });

    try {
      const response = await fetchApi(`${getApiUrl()}${endpoints.subscribe()}`, {
        method: 'POST',
        body: JSON.stringify({ email })
      });

      setStatus({ type: 'success', message: response.message });
      setEmail('');
    } catch (error) {
      setStatus({ type: 'error', message: error.message });
      navigate('/error-submit', { 
        state: { 
          error: error.message,
          source: 'subscriber',
          time: new Date().toISOString()
        } 
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <footer className="footer">
      <div className="container">
        <hr className="light-divider"></hr>
        
        {/* Content sections wrapper */}
        <div className="content-wrapper">
          {/* stay informed section */}
          <div className="footer-section">
            <h2 className="footer-title">STAY INFORMED</h2>
            <p className="footer-text">Follow LY news, companies, & more</p>
            
            <form onSubmit={handleSubscribe} className="footer-form">
              <input 
                type="email" 
                placeholder="Email Address" 
                className="footer-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isSubmitting}
              />
              <button 
                type="submit" 
                className="footer-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'SUBSCRIBING...' : 'SUBSCRIBE'}
              </button>
            </form>
            
            {status.message && (
              <p className={`footer-message ${status.type}`}>
                {status.message}
              </p>
            )}
          </div>

          {/* next batch section */}
          <div className="footer-section">
            <h2 className="footer-title">APPLY FOR FUNDING</h2>
            <p className="footer-text">1 year to build something great</p>
            <button onClick={() => window.location.href = '/apply'} className="footer-button">APPLY FOR NEXT CYCLE</button>
          </div>
          
        </div>
        
        <hr className="light-divider"></hr>

        <div className="footer-links">

        {/* Bottom bar with logo */}
        <div className="link-column">
          <div className="social-links">
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleHomeClick}>
              <h2>LEAPYEAR</h2>
            </Link>
            <div className="social-icons-container">
              <a href="https://x.com/_leapyear_" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="social-link">
                  <img 
                    src="https://img.icons8.com/?size=50&id=6Fsj3rv2DCmG&format=png&color=FFFFFF" 
                    alt="Twitter/X" 
                    className="social-icon" 
                  />
                </a>
                <a href="https://www.linkedin.com/school/leapyear" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="social-link">
                  <img 
                    src="https://img.icons8.com/?size=50&id=447&format=png&color=FFFFFF" 
                    alt="LinkedIn" 
                    className="social-icon" 
                  />
                </a>
            </div>
          </div>
        </div>
        {/* Adding sitemap links */}
        
          <div className="link-column">
            <Link to="/" onClick={handleLinkClick}>Home</Link>
            <Link to="/apply" onClick={handleLinkClick}>Apply</Link>
            <Link to="/scout" onClick={handleLinkClick}>Scout</Link>
          </div>

          <div className="link-column">
            <Link to="/investments" onClick={handleLinkClick}>Investments</Link>
            <Link to="/people" onClick={handleLinkClick}>People</Link>
            <Link to="/faq" onClick={handleLinkClick}>FAQ</Link>
          </div>

        </div>

      </div>
    </footer>
  );
}

export default MainFooter;







