import React from 'react';
import MatrixRain from './matrix-rain';

function LoadingModal({ isOpen, message = "The server is processing..." }) {
  if (!isOpen) return null;

  return (
    <div className="loading-modal-overlay">
      <MatrixRain />
      <div className="loading-modal-content">
        <p>{message}</p>
      </div>
    </div>
  );
}

export default LoadingModal; 