import React, { useEffect } from 'react';

function PointInTime() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="container">
        <div className="post-apply-container">
            <h1 className="page-title">A Point In Time</h1>
            <p className="info-text">LeapYear started with the belief that young people can build incredible things and that if more builders had a first believer, providing a little help and a little capital, more incredible things would be built.</p>
            <p className="info-text">So, we raised some capital with the plan to fund 100+ teams over the first few years of LeapYear. This fund allows us to fund 6-7 teams every 3 months.</p>
            <p className="info-text">The flip side is that we can <strong><u className="info-text">only</u></strong> afford to fund 6-7 teams every 3 months. So that means we have to say “No” to many great builders.</p>
            <p className="info-text">No is not inspiring. No is not helping builders. No sucks.</p>
            <p className="info-text">So, every 3 months, we review applications and run interviews to fund a few teams. And the reality is that process is imperfect.</p>
            <p className="info-text">It accomplishes some things, but when many talented teams apply, we end up making decisions quickly with limited information, all tied to a <strong><u className="info-text">single point in time</u></strong>.</p>
            <p className="info-text">So we fund great teams, but certainly there are many great teams we don't fund.</p>
            <p className="info-text">This became very clear within our first few months, particularly by watching and learning from the teams we didn’t fund.</p>
            <p className="info-text">Within a few months, we had teams we didn’t fund:</p>
            <p className="info-text">- get funded by YCombinator</p>
            <p className="info-text">- get funded by incredible fellowships (Z-Fellows, Telora, + others)</p>
            <p className="info-text">- launch & scale revenue to $100k+</p>
            <p className="info-text">The last one was ironic because when we read their application, the concept seemed too ambitious. Like it was a really ambitious idea on a piece of paper and we weren’t sure if the team was serious or not. Then, they launched and customers wanted their product.</p>
            <p className="info-text">This all might sound weird coming from an investor. But we are a believer that the world is not always black and white. Information is constantly evolving. We try to assess applications with the limited info we have at the time.</p>
            <p className="info-text">So we encourage you to keep building. The world needs more builders.</p>
            <p className="info-text">We also encourage you to reapply. We fund new teams every 3 months and a number of teams we've funded have been repeat applicants. </p>
            <p className="info-text">If anything, seeing repeat applications actually gives us 2 points in time to better understand a team's growth and potential.</p>
            <p className="info-text">And know that we never forget a team we didn’t fund and that goes on to accomplish great things. Learning from those mistakes is how we make our process better and better.</p>
            <br></br>
            <p className="info-text">With love,</p>
            <p className="info-text">the LeapYear Team</p>
        </div>
    </main>
  );
}

export default PointInTime; 