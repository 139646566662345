import React, { useEffect } from 'react';

function PostSubmit() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="container">
        <div className="post-submit-container">
            <h1 className="page-title">Thank you!</h1>
            <p className="info-text">Confirmed we have your submission.</p>
            <p className="info-text">We will be in touch.</p>
        </div>
    </main>
  );
}

export default PostSubmit; 