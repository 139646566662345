import React from 'react';
import {} from 'react-router-dom';

function ErrorSubmit() {

  return (
    <main className="container">
        <div className="post-apply-container">
            <h1 className="page-title">Ah Sh*t...</h1>
            <p className="info-text">I'm sorry. We probably wrote some bad code.</p>
            <p className="info-text">If you got this. Feel free to email us at build@takeleapyear.com.</p>
            <p className="info-text">We will review & get back to you soon.</p>
            <p className="info-text">Sincerely, the LeapYear Team</p>
        </div>
    </main>
  );
}

export default ErrorSubmit;