import { useState, useEffect, useCallback } from 'react';
import { fetchApi } from '../api';

// Global cache for website inputs
let globalCache = {
  data: null,
  timestamp: null,
  promise: null
};

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Function to check if cache is valid
const isCacheValid = () => {
  return (
    globalCache.data !== null &&
    globalCache.timestamp !== null &&
    Date.now() - globalCache.timestamp < CACHE_DURATION
  );
};

// Function to load website inputs
const loadWebsiteInputs = async (force = false) => {
  // If we have a valid cache and don't force refresh, return cached data
  if (!force && isCacheValid()) {
    return globalCache.data;
  }

  // If there's already a request in flight, return its promise
  if (globalCache.promise) {
    return globalCache.promise;
  }

  // Create new request promise
  globalCache.promise = fetchApi('/api/public/website-inputs/', {
    method: 'GET',
    params: {
      table: 'website_inputs',
      name: 'Next Batch'
    }
  }).then(response => {
    if (response.data && response.data.length > 0) {
      // Update cache
      globalCache.data = response.data[0].input_json;
      globalCache.timestamp = Date.now();
      return globalCache.data;
    }
    return null;
  }).finally(() => {
    globalCache.promise = null;
  });

  return globalCache.promise;
};

// Hook to use website inputs
export function useWebsiteInputs() {
  const [inputs, setInputs] = useState(globalCache.data);
  const [loading, setLoading] = useState(!isCacheValid());
  const [error, setError] = useState(null);

  // Function to force refresh the data
  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const data = await loadWebsiteInputs(true);
      setInputs(data);
      setError(null);
    } catch (err) {
      console.error('Error loading website inputs:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let mounted = true;

    const loadData = async () => {
      if (!isCacheValid()) {
        setLoading(true);
        try {
          const data = await loadWebsiteInputs();
          if (mounted) {
            setInputs(data);
            setError(null);
          }
        } catch (err) {
          console.error('Error loading website inputs:', err);
          if (mounted) {
            setError(err);
          }
        } finally {
          if (mounted) {
            setLoading(false);
          }
        }
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  return { inputs, loading, error, refresh };
} 