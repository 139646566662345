import React, { useState, useEffect, useRef } from 'react';
import { useWebsiteInputs } from '../../utils/hooks/useWebsiteInputs';

// Pre-defined arrays with different orders
const schoolData = {
    Stanford: {
      logo: "https://identity.stanford.edu/wp-content/uploads/sites/3/2020/07/block-s-right.png"
    },
    Northwestern: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Northwestern_Wildcats_logo.svg/1200px-Northwestern_Wildcats_logo.svg.png"
    },
    UChicago: {
      logo: "https://upload.wikimedia.org/wikipedia/en/thumb/7/79/University_of_Chicago_shield.svg/1200px-University_of_Chicago_shield.svg.png"
    },
    "WashU St. Louis": {
      logo: "https://www.state.gov/wp-content/uploads/2020/02/1200px-WashU_St._Louis_seal.svg.png"
    },
    "UT-Austin": {
      logo: "https://giving.utexas.edu/wp-content/uploads/2022/01/0_Texas-Longhorns-01.png"
    },
    "Johns Hopkins": {
      logo: "https://kimlab.bme.jhu.edu/wp-content/uploads/2022/04/cropped-cropped-Daco_4928067.png"
    },
    Maryland: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Maryland_Terrapins_logo.svg/1200px-Maryland_Terrapins_logo.svg.png"
    },
    UMASS: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/UMass_Amherst_athletics_logo.svg/1200px-UMass_Amherst_athletics_logo.svg.png"
    },
    Virginia: {
      logo: "https://sportslogohistory.com/wp-content/uploads/2020/04/virginia_cavaliers_2020-pres.png"
    },
    Penn: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Penn_Quakers_logo.svg/1200px-Penn_Quakers_logo.svg.png"
    },
    "North Carolina": {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/North_Carolina_Tar_Heels_logo.svg/800px-North_Carolina_Tar_Heels_logo.svg.png"
    },
    Michigan: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Michigan_Wolverines_logo.svg/1200px-Michigan_Wolverines_logo.svg.png"
    },
    Illinois: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Illinois_Fighting_Illini_logo.svg/800px-Illinois_Fighting_Illini_logo.svg.png"
    },
    "Oxford (UK)": {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Oxford-University-Circlet.svg/877px-Oxford-University-Circlet.svg.png"
    },
    Vanderbilt: {
      logo: "https://1000logos.net/wp-content/uploads/2020/02/Vanderbilt-Commodores-Logo.png"
    },
    Emory: {
      logo: "https://i.namu.wiki/i/FtFyEN9Dr2UXEhm6AdJWYdwKpvUHBK96QkCfpa9jQC-08Rt10IGFJUrEn_6WECi9PpyiqEDqd0STk91jddJ4dg.webp"
    }
  };
  
  const labelArrays = {
    first: [
    "Stanford",
    "UChicago",
    "WashU St. Louis",
    "UT-Austin",
    "Virginia",
    "Penn",
    "Illinois",
    "Johns Hopkins",
    "Vanderbilt"
    ],
    second: [
    "Michigan",
    "Northwestern",
    "Vanderbilt",
    "Maryland",
    "Oxford (UK)",
    "North Carolina",
    "Stanford",
    "Emory",
    "UMASS"
    ]
  };
  
  const LabelScroll = ({ speed = '30s', reverse = false, variant = 'first' }) => {
    const labels = labelArrays[variant];
    const totalLabels = labels.length;
  
    return (
      <div className="logo-loop">
        <div 
          className="logo-track"
          style={{
            '--total-items': totalLabels,
            '--animation-duration': speed,
            'flexDirection': reverse ? 'row-reverse' : 'row'
          }}
        >
          {[...labels, ...labels].map((label, index) => (
            <span 
              key={`label-${index}`}
              className="label"
            >
              {schoolData[label]?.logo && (
                <img 
                  src={schoolData[label].logo} 
                  alt={`${label} logo`}
                  className="school-logo"
                />
              )}
              {label}
            </span>
          ))}
        </div>
      </div>
    );
  }; 
       

function NextBatch({ inContainer = true }) {
  const { inputs: websiteInputs } = useWebsiteInputs();
  const [count, setCount] = useState(0);
  const countRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    const currentRef = countRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible && websiteInputs?.["Total Investments"] > 0) {
      let start = 0;
      const end = websiteInputs?.["Total Investments"] ? parseInt(websiteInputs["Total Investments"]) : 0;
      const duration = 1500;
      const increment = 1;
      const stepTime = Math.abs(Math.floor(duration / (end - start)));

      const timer = setInterval(() => {
        start += increment;
        setCount(start);

        if (start >= end) {
          clearInterval(timer);
        }
      }, stepTime);

      return () => clearInterval(timer);
    }
  }, [isVisible, websiteInputs]);

  return (
    <main>
      {inContainer && (
        <div className="container">
          <section className="content-section">
            <div className="list-grid">
              <div className="list-item crossed-out no-bullet">Accelerator</div>
              <div className="list-item bold no-bullet">First-Believer Fund</div>
            </div>
            <p>Help the ambitious start.</p>
            <p>Give them a network.</p>
            <p>Be their first believer.</p>
          </section>
          <section className="content-section">
            <p ref={countRef}><span className="counter">{count}</span> teams backed so far.</p>
            <p>60%+ raised additional capital.</p>
            <p>40%+ applied at idea stage.</p>
            <LabelScroll speed="90s" variant="first" />
            <LabelScroll speed="90s" variant="second" />
          </section>
        </div>
      )}
      {!inContainer && (
        <div>
          <section className="content-section">
            <div className="list-grid">
              <div className="list-item crossed-out no-bullet">Accelerator</div>
              <div className="list-item bold no-bullet">First-Believer Fund</div>
            </div>
            <p>Help the ambitious start.</p>
            <p>Give them a network.</p>
            <p>Be their first believer.</p>
          </section>
          <section className="content-section">
            <p ref={countRef}><span className="counter">{count}</span> teams backed so far.</p>
            <p>60%+ raised additional capital.</p>
            <p>40%+ applied at idea stage.</p>
            <LabelScroll speed="90s" variant="first" />
            <LabelScroll speed="90s" variant="second" />
          </section>
        </div>
      )}
    </main>
  );
}

export default NextBatch;