import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Alumni from './investments.js';
import NextBatch from '../common/first-believer-fund.js';
import PeopleIntro from '../common/people-intro.js';
import RotatingButton from '../bonus/rotating-button.js';
import { useWebsiteInputs } from '../../utils/hooks/useWebsiteInputs';

function Intro() {
  const { inputs: websiteInputs, loading } = useWebsiteInputs();
  const [isOpenLife, setIsOpenLife] = useState(false); // State for Life On LeapYear
  const [isOpenCommit, setIsOpenCommit] = useState(false); // State for Why 1 Year Commit
  const [isOpenWhyLeapYear, setIsOpenWhyLeapYear] = useState(false); // State for Why LeapYear section
  const [isOpenBuilders, setIsOpenBuilders] = useState(false); // State for the expand section
  const [isOpenFAQ, setIsOpenFAQ] = useState(false); // State for Short Answer FAQ

  const toggleLifeSection = () => {
    setIsOpenLife(!isOpenLife); // Toggle Life On LeapYear section
  };

  const toggleCommitSection = () => {
    setIsOpenCommit(!isOpenCommit); // Toggle Why 1 Year Commit section
  };

  const toggleWhyLeapYearSection = () => {
    setIsOpenWhyLeapYear(!isOpenWhyLeapYear); // Toggle Why LeapYear section
  };

  const toggleBuildersSection = () => {
    setIsOpenBuilders(!isOpenBuilders); // Toggle the section open/close
  };

  const toggleFAQSection = () => {
    setIsOpenFAQ(!isOpenFAQ); // Toggle Short Answer FAQ section
  };

  // Prepare the API data for the button
  const buttonApiData = !loading && websiteInputs?.['Batch Name'] 
    ? `APPLY FOR ${websiteInputs['Batch Name'].toUpperCase()}`
    : 'APPLY FOR FUNDING';

  return (
    <>
      <Helmet>
        <title>LeapYear | 1 Year To Build</title>
        <meta name="description" content="LeapYear funds ambitious students, dropouts, & grads to build generational companies." />
        <meta name="keywords" content="startups, student founders, seed funding, venture capital, dropouts, students, startups" />
        <meta property="og:title" content="LeapYear | 1 Year To Build" />
        <meta property="og:description" content="For students, dropouts, & grads. We fund builders to take 1 year to build something ambitious." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://www.takeleapyear.com/" />
      </Helmet>

      <main className="container" role="main">
          <>
            <section className="content-section hero" aria-labelledby="hero-title">
              <h1 className="hero-title" id="hero-title">LEAPYEAR</h1>
              <div className="hero-content">
                <p>For students, dropouts, & grads.</p>
                <p>We fund builders to take 1 year to build something ambitious.</p>
                <p>We invest in builders anywhere, including teams still in school.</p>
                <p>Each year, we back 25-30 teams. We fund new teams every 3 months.</p>
                <p className="cta-text">Our ask is <span className="cta-text">you commit</span> and <span className="cta-text">build</span>.</p>
                
                <RotatingButton 
                  href="/apply"
                  className="cta-button"
                  role="button"
                  ariaLabel={`Apply for ${websiteInputs?.['Batch Name']?.toUpperCase() || 'Funding'}`}
                  apiData={buttonApiData}
                  isLoading={loading}
                  rotatingTexts={["BE AMBITIOUS..."]}
                />
              </div>
            </section>

            <hr className="light-divider" aria-hidden="true"></hr>

            <NextBatch inContainer={false} />

            <hr className="light-divider" aria-hidden="true"></hr>

            <section className="content-section" aria-labelledby="built-by-title">
              <h2 className="section-title" id="built-by-title">Things Built By People With Little Experience</h2>
              <p className="intro-text">The future is built by the young & ambitious. Always has. Always will.</p>
              <div className="built-by-container" role="list">
                <div className="built-by-item" role="listitem">
                  <div className="image-container">
                    <img src="https://logos-world.net/wp-content/uploads/2020/05/Facebook-Logo-2019.png" alt="Facebook Logo" className="company-logo" loading="lazy" />
                  </div>
                  <div className="text-container">
                    <span>Harvard Dorm</span>
                  </div>
                </div>
                <div className="built-by-item" role="listitem">
                  <div className="image-container">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Airbnb_Logo_B%C3%A9lo.svg/1280px-Airbnb_Logo_B%C3%A9lo.svg.png" alt="Airbnb Logo" className="company-logo" loading="lazy" />
                  </div>
                  <div className="text-container">
                    <span>RISD Friends</span>
                  </div>
                </div>
                <div className="built-by-item" role="listitem">
                  <div className="image-container">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Logo_of_YouTube_%282013-2015%29.svg/2560px-Logo_of_YouTube_%282013-2015%29.svg.png" alt="YouTube Logo" className="company-logo" loading="lazy" />
                  </div>
                  <div className="text-container">
                    <span>Illinois Friends</span>
                  </div>
                </div>
                <div className="built-by-item" role="listitem">
                  <div className="image-container">
                    <img src="https://i.pinimg.com/originals/45/43/7d/45437d722655e2d1e8eae551dad8f29a.png" alt="DoorDash Logo" className="company-logo" loading="lazy" />
                  </div>
                  <div className="text-container">
                    <span>Stanford Class</span>
                  </div>
                </div>
                <div className="built-by-item" role="listitem">
                  <div className="image-container">
                    <img src="https://logos-world.net/wp-content/uploads/2020/10/Reddit-Logo-2017.png" alt="Reddit Logo" className="company-logo" loading="lazy" />
                  </div>
                  <div className="text-container">
                    <span>UVA Friends</span>
                  </div>
                </div>
                <div className="built-by-item" role="listitem">
                  <div className="image-container">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/1200px-Google_2015_logo.svg.png" alt="Google Logo" className="company-logo" loading="lazy" />
                  </div>
                  <div className="text-container">
                    <span>Stanford PHDs</span>
                  </div>
                </div>
                <div className="built-by-item" role="listitem">
                  <div className="image-container">
                    <img src="https://logos-world.net/wp-content/uploads/2024/09/Figma-Logo.png" alt="Figma Logo" className="company-logo" loading="lazy" />
                  </div>
                  <div className="text-container">
                    <span>Brown Friends</span>
                  </div>
                </div>
                <div className="built-by-item" role="listitem">
                  <div className="image-container">
                    <img src="https://logos-world.net/wp-content/uploads/2021/12/Venmo-Logo.png" alt="Venmo Logo" className="company-logo" loading="lazy" />
                  </div>
                  <div className="text-container">
                    <span>UPenn Roommates</span>
                  </div>
                </div>
                <div className="built-by-item last-item" role="listitem">
                  <div className="image-container">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Twitch_logo_%28wordmark_only%29.svg/800px-Twitch_logo_%28wordmark_only%29.svg.png" alt="Twitch Logo" className="company-logo" loading="lazy" />
                  </div>
                  <div className="text-container">
                    <span>Yale Friends</span>
                  </div>
                </div>
              </div>
              <p>... you get the idea</p>
            </section>

            <hr className="light-divider" aria-hidden="true"></hr>

            <section className="content-section" aria-labelledby="bet-title">
              <h2 className="section-title" id="bet-title">
                This Is A Bet.
              </h2>
              <div className="section-content">
                <p className="intro-text">
                  A bet that if the young and ambitious go build for 1 year, great things will happen.
                </p>
                <p className="intro-text">
                  We fund a few teams every 3 months. We value talent & grit over age. 
                  We look for people eager to do the best work of their lives.
                </p>
              </div>
            </section>

            <hr className="light-divider" aria-hidden="true"></hr>

            <section className="content-section" aria-labelledby="investment-title">
              <h2 className="section-title" id="investment-title">Investment.</h2>
              <div className="section-content">
                <p className="text-highlight" aria-label="Investment terms">{websiteInputs?.['Funding']} for {websiteInputs?.['Equity']} Equity</p>
                <p className="intro-text">We invest as a first-believer fund.</p>
                <p className="intro-text">
                  We think a lot can be done with a little. So we write a small check as a first believer.
                </p>
                <p className="intro-text">
                  Our investment is low dilution. This gives founders flexibility to grow how they choose, 
                  whether through an accelerator, raising a pre-seed, or organically.
                </p>
              </div>
            </section>

            <hr className="light-divider" aria-hidden="true"></hr>

            <section className="content-section" aria-labelledby="how-it-works-title">
              <h2 className="section-title" id="how-it-works-title">
                How It Works.
              </h2>
              <div className="section-content">
                <p className="intro-text">We tried to think first principles.</p>
                <p className="intro-text">
                  We believe builders can come from anywhere, so we don't care if you are in SF, NYC, or Omaha.
                </p>
                <p className="intro-text">
                  The internet is filled with great information. So we don't do programming. When you want 1/1 advice, just ask. We aim to help.
                </p>
                <p className="intro-text">
                  Every story is unique. So we invest in anyone - grads, dropouts, & builders still in school. We trust that if you are serious, you will find the time.
                </p>
                <p className="intro-text">
                  We run small adviser sessions where experts offer feedback to teams. They are optional but hopefully helpful.
                </p>
                <p className="intro-text">
                  Because we invest anywhere, we run on the internet (e.g. video / chat).
                </p>
              </div>
            </section>

            <hr className="light-divider" aria-hidden="true"></hr>

            <PeopleIntro />

            <hr className="light-divider" aria-hidden="true"></hr>
            
            <section className="expand-sections-container">
              <h2 className="extra-section-title" id="additional-info">Additional Info:</h2>

              <section className="expand-section">
                <h3 
                  className="expand-section-title" 
                  onClick={toggleWhyLeapYearSection} 
                  onKeyPress={(e) => e.key === 'Enter' && toggleWhyLeapYearSection()}
                  role="button"
                  tabIndex={0}
                  aria-expanded={isOpenWhyLeapYear}
                  aria-controls="why-leapyear-content"
                  id="why-leapyear-title"
                >
                  Why LeapYear
                  <span className="expand-icon" aria-hidden="true">{isOpenWhyLeapYear ? '−' : '+'}</span>
                </h3>
                <div 
                  id="why-leapyear-content" 
                  className={`section-content ${isOpenWhyLeapYear ? 'expanded' : ''}`}
                  aria-labelledby="why-leapyear-title"
                  hidden={!isOpenWhyLeapYear}
                >
                  <div className="section-spacer"></div>
                  <ul className="intro-list">
                    <li>
                      <strong>Your First Investor</strong> 
                      <ul>
                        <li>Startups are built on momentum. We invest early and wherever you are located</li>
                      </ul>
                    </li>
                    <li>
                      <strong>Flexibility</strong> 
                      <ul>
                        <li>We don't make you move or drop out. As long as you commit, we will fund you</li>
                      </ul>
                    </li>
                    <li>
                      <strong>Support When You Want It</strong> 
                      <ul>
                        <li>We offer 1/1 advice when you want it. It's your business though so we advise, not dictate</li>
                      </ul>
                    </li>
                    <li>
                      <strong>Top 1% Builder Network</strong> 
                      <ul>
                        <li>We are supported by some world-class builders eager to help the next generation</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>

              <section className="expand-section">
                <h3 
                  className="expand-section-title" 
                  onClick={toggleCommitSection}
                  onKeyPress={(e) => e.key === 'Enter' && toggleCommitSection()}
                  role="button"
                  tabIndex={0}
                  aria-expanded={isOpenCommit}
                  aria-controls="commit-content"
                  id="commit-title"
                >
                  Why 1 Year Commit
                  <span className="expand-icon" aria-hidden="true">{isOpenCommit ? '−' : '+'}</span>
                </h3>
                <div 
                  id="commit-content"
                  className={`section-content ${isOpenCommit ? 'expanded' : ''}`}
                  aria-labelledby="commit-title"
                  hidden={!isOpenCommit}
                >
                  <div className="section-spacer"></div>
                  <p className="intro-text">Great things take time to build. We invest in builders, not ideas</p>
                  <p className="intro-text">There will be many ups and downs. It will be hard. Hard things are hard</p>
                  <p className="intro-text">But if you are relentless. We believe you will find a way to succeed</p>
                  <p className="intro-text">Our ask though, is you commit to building for 1 year minimum</p>
                  <p className="intro-text">It takes 6-12 months to get to the frontier of an industry. That is where the opportunity is</p>
                  <p className="intro-text">So perhaps the first idea might not work. Perhaps you might try 3 ideas</p>
                  <p className="intro-text">Just keep building, talking to customers, & getting 1% better daily</p>
                </div>
              </section>

              <section className="expand-section">
                <h3 
                  className="expand-section-title" 
                  onClick={toggleLifeSection}
                  onKeyPress={(e) => e.key === 'Enter' && toggleLifeSection()}
                  role="button"
                  tabIndex={0}
                  aria-expanded={isOpenLife}
                  aria-controls="life-content"
                  id="life-title"
                >
                  Life On LeapYear
                  <span className="expand-icon" aria-hidden="true">{isOpenLife ? '−' : '+'}</span>
                </h3>
                <div 
                  id="life-content"
                  className={`section-content ${isOpenLife ? 'expanded' : ''}`}
                  aria-labelledby="life-title"
                  hidden={!isOpenLife}
                >
                  <div className="section-spacer"></div>
                  <p className="intro-text">Talk to users. Understand their problems. Build solutions</p>
                  <p className="intro-text">Work Hard. Only the relentless will succeed</p>
                  <p className="intro-text">We will check in with you if you want help but all optional</p>
                  <p className="intro-text">Thats it. Just go build</p>
                </div>
              </section>

              <section className="expand-section">
                <h3 
                  className="expand-section-title" 
                  onClick={toggleFAQSection}
                  onKeyPress={(e) => e.key === 'Enter' && toggleFAQSection()}
                  role="button"
                  tabIndex={0}
                  aria-expanded={isOpenFAQ}
                  aria-controls="faq-content"
                  id="faq-title"
                >
                  Short Answer FAQ
                  <span className="expand-icon" aria-hidden="true">{isOpenFAQ ? '−' : '+'}</span>
                </h3>
                <div 
                  id="faq-content"
                  className={`section-content ${isOpenFAQ ? 'expanded' : ''}`}
                  aria-labelledby="faq-title"
                  hidden={!isOpenFAQ}
                >
                  <div className="section-spacer"></div>

                  <ul className="intro-list">
                    <li>
                      <strong>How Does It Work?</strong> 
                      <ul>
                        <li>Teams apply. We fund a few. They build. We offer help</li>
                      </ul>
                    </li>
                    <li>
                      <strong>Is it Remote / Virtual?</strong> 
                      <ul>
                        <li>Yes. This allows us to invest anywhere</li>
                      </ul>
                    </li>
                    <li>
                      <strong>What Is Required?</strong> 
                      <ul>
                        <li>Commit. Talk to customers. Build something ambitious</li>
                      </ul>
                    </li>
                    <li>
                      <strong>Is This An Accelerator?</strong> 
                      <ul>
                        <li>No. LeapYear is a 1-year commit. Not 3 months. Great things take time</li>
                      </ul>
                    </li>
                    <li>
                      <strong>What Happens After 1 Year?</strong> 
                      <ul>
                        <li>Ideally, your work is scaling. Maybe it doesn't but you learn a lot</li>
                      </ul>
                    </li>
                    <li>
                      <strong>What Stage Do You Invest At?</strong> 
                      <ul>
                        <li>Day 1. That's why we are willing to back teams still in school</li>
                      </ul>
                    </li>
                    <li>
                      <strong>Do I Need to Still Be In School?</strong> 
                      <ul>
                        <li>No. We review all applications. We've funded many grads & dropouts</li>
                      </ul>
                    </li>
                    <li>
                      <strong>If I've Already Taken Funding, Can I Still Apply?</strong> 
                      <ul>
                        <li>Yes</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>

              <section className="expand-section">
                <h3 
                  className="expand-section-title" 
                  onClick={toggleBuildersSection}
                  onKeyPress={(e) => e.key === 'Enter' && toggleBuildersSection()}
                  role="button"
                  tabIndex={0}
                  aria-expanded={isOpenBuilders}
                  aria-controls="builders-content"
                  id="builders-title"
                >
                  Past Investments ({websiteInputs?.['Total Investments']}):
                  <span className="expand-icon" aria-hidden="true">{isOpenBuilders ? '−' : '+'}</span>
                </h3>
                <div 
                  id="builders-content"
                  className={`section-content ${isOpenBuilders ? 'expanded' : ''}`}
                  aria-labelledby="builders-title"
                  hidden={!isOpenBuilders}
                >
                  <div className="section-spacer"></div> 
                  <Alumni showTitle={false} />
                </div>
              </section>
            </section>
          </>
      </main>
    </>
  );
}

export default Intro;