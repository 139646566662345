import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { endpoints, fetchApi } from '../../utils/api';
import LoadingModal from '../bonus/api-loading-modal';
import { CHAR_LIMITS, getRemainingChars } from '../../utils/constants';

function FundingTimeline() {
  const timelineData = [
    { year: "2025", month: "Jan", teams: 7, upcoming: false, funded: true },
    { year: "2025", month: "Apr", teams: "6-7", upcoming: true, funded: false },
    { year: "2025", month: "Jul", teams: "6-7", upcoming: false, funded: false },
    { year: "2025", month: "Oct", teams: "6-7", upcoming: false, funded: false },
  ]

  const timelineStyles = {
    container: {
      width: '100%',
      maxWidth: '600px',
      padding: '0.5rem 0rem',
      margin: '2rem 0rem',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      borderRadius: '10px',
      backgroundColor: 'rgba(255, 255, 255, 0.05)'
    },
    titleContainer: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      padding: '0.5rem',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      marginTop: '-0.5rem'
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      margin: 0
    },
    timeline: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0.5rem 0rem'
    },
    timelineItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '25%'
    },
    year: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '5px'
    },
    month: {
      fontSize: '16px',
      color: '#666',
      marginBottom: '15px'
    },
    circle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      backgroundColor: '#000',
      border: '4px solid #e0e0e0',
      position: 'relative',
      zIndex: '1'
    },
    upcomingCircle: {
      backgroundColor: '#f0f0f0',
      color: 'black',
      border: '4px solid #e0e0e0'
    },
    teamCount: {
      fontSize: '1.3rem',
      fontWeight: 'bold',
      lineHeight: '1'
    },
    teamsText: {
      fontSize: '12px',
      marginTop: '5px'
    },
    upcomingLabel: {
      marginTop: '0.5rem',
      padding: '0.25rem 0.5rem',
      backgroundColor: '#f0f0f0',
      borderRadius: '20px',
      fontSize: '0.75rem',
      color: 'black',
      fontWeight: 'bold'
    },
    fundedLabel: {
      marginTop: '0.5rem',
      padding: '0.25rem 0.5rem',
      backgroundColor: '#000',
      borderRadius: '20px',
      fontSize: '0.75rem',
      color: 'white',
      fontWeight: 'bold'
    },
    connectorLine: {
      position: 'absolute',
      top: '110px',
      left: '10%',
      right: '10%',
      height: '2px',
      backgroundColor: '#e0e0e0', 
      zIndex: '0'
    },
    noteContainer: {
      marginTop: '1.5rem',
      borderTop: '1px solid rgba(255, 255, 255, 0.1)',
      width: '100%'
    },
    noteTitleContainer: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      width: 'fit-content',
      marginTop: '1rem',
      marginLeft: '1rem',
      borderRadius: '5px'
    },
    noteTitle: {
      fontSize: '1rem',
      fontWeight: 'bold',
      margin: 0
    },
    noteContent: {
      padding: '0.5rem 1rem',
      fontSize: '0.9rem',
      color: '#f0f0f0'
    }
  };

  return (
    <div style={timelineStyles.container}>
      <div style={timelineStyles.titleContainer}>
        <h2 style={timelineStyles.title}>LeapYear 2025 Funding Cycles</h2>
      </div>

      <div style={timelineStyles.timeline}>
        {/* Connector line */}
        <div style={timelineStyles.connectorLine}></div>
        
        {timelineData.map((item, index) => (
          <div key={index} style={timelineStyles.timelineItem}>
            {/* Year and Month */}
            <div style={timelineStyles.year}>{item.year}</div>
            <div style={{
              ...timelineStyles.month,
              ...(item.upcoming ? { color: '#fff' } : {})
            }}>
              {item.month}
            </div>

            {/* Circle with team count and "Investments" text */}
            <div
              style={{
                ...timelineStyles.circle,
                ...(item.upcoming ? timelineStyles.upcomingCircle : {})
              }}
            >
              <span style={timelineStyles.teamCount}>{item.teams}</span>
              <span style={timelineStyles.teamsText}>Teams</span>
            </div>

            {/* Upcoming label */}
            {item.upcoming && (
              <div style={timelineStyles.upcomingLabel}>Next Cycle</div>
            )}
            
            {/* Funded label */}
            {item.funded && (
              <div style={timelineStyles.fundedLabel}>Funded</div>
            )}
          </div>
        ))}
      </div>
      
      <div style={timelineStyles.noteContainer}>
        <div style={timelineStyles.noteTitleContainer}>
          <h3 style={timelineStyles.noteTitle}>Repeat Applicants Welcome</h3>
        </div>
        <div style={timelineStyles.noteContent}>
          25% of recent teams were repeat apps.
        </div>
      </div>
    </div>
  );
}

function Apply() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [batchData, setBatchData] = useState(null);
  const [isPastDeadline, setIsPastDeadline] = useState(false);
  const [formData, setFormData] = useState({
    projectName: '',
    oneLinerDescription: '',
    problem: '',
    solution: '',
    targetCustomer: '',
    whyEquipped: '',
    whyDoThis: '',
    relevantLinks: '',
    founders: [{
      firstName: '',
      lastName: '',
      email: '',
      links: '',
      role: '',
      age: ''
    }],
    howHeard: '',
    additionalInfo: '',
    incorporated: null,
    corpName: '',
    workingDuration: '',
    takenInvestment: null,
    investmentAmount: '',
    hasCustomers: null,
    customerCount: '',
    hasRevenue: null,
    revenueAmount: '',
    fullTimeCommitment: ''
  });
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const fetchBatchData = async () => {
      try {
        const response = await fetchApi('/api/public/website-inputs/', {
          method: 'GET',
          params: {
            table: 'website_inputs',
            name: 'Next Batch'
          }
        });
        
        console.log('Response:', response);
        
        if (response.data && response.data.length > 0) {
          setBatchData(response.data[0].input_json);
          
          // Check if current date is past deadline + 36 hours
          const deadlineDate = new Date(response.data[0].input_json['App Deadline']);
          const extendedDeadline = new Date(deadlineDate.getTime() + (48 * 60 * 60 * 1000)); // Add 48 hours in milliseconds
          const currentDate = new Date();
          setIsPastDeadline(currentDate > extendedDeadline);
        }
      } catch (error) {
        console.error('Error fetching batch data:', error);
        setError({
          message: error.message,
          source: 'batch_data',
          time: new Date().toISOString()
        });
      }
    };

    fetchBatchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (e, founderIndex = null) => {
    const { id, value } = e.target;
    
    if (founderIndex !== null) {
      // Handle founder field changes
      const field = id.split('-')[1]; // e.g., "founder-firstName" -> "firstName"
      setFormData(prev => ({
        ...prev,
        founders: prev.founders.map((founder, idx) => 
          idx === founderIndex 
            ? { ...founder, [field]: value }
            : founder
        )
      }));
    } else {
      // Handle other field changes
      setFormData(prev => ({
        ...prev,
        [id]: value
      }));
    }
  };

  const handleRoleChange = (founderIndex, role) => {
    setFormData(prev => ({
      ...prev,
      founders: prev.founders.map((founder, idx) => 
        idx === founderIndex 
          ? { ...founder, role: role }
          : founder
      )
    }));
  };

  const addFounder = () => {
    if (formData.founders.length < 4) {
      setFormData(prev => ({
        ...prev,
        founders: [...prev.founders, {
          firstName: '',
          lastName: '',
          email: '',
          links: '',
          role: '',
          age: ''
        }]
      }));
    }
  };

  const removeFounder = (indexToRemove) => {
    setFormData(prev => ({
      ...prev,
      founders: prev.founders.filter((_, index) => index !== indexToRemove)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null); // Clear any previous errors
    
    try {
      await fetchApi(endpoints.submitApplication(), {
        method: 'POST',
        body: JSON.stringify(formData),
      });

      // Redirect to post-apply page on successful submission
      navigate('/post-apply');
    } catch (error) {
      console.error('Error submitting form:', error);
      setError({
        message: error.message,
        source: 'apply',
        time: new Date().toISOString()
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResetRole = (founderIndex) => {
    setFormData(prev => ({
      ...prev,
      founders: prev.founders.map((founder, idx) => 
        idx === founderIndex 
          ? { ...founder, role: '' }
          : founder
      )
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  return (
    <div className="container">
      <LoadingModal isOpen={isSubmitting} message={"1 Sec. The Server is Cooking..."} />
      <h1 className="page-title">APPLY.{batchData?.['Batch Year']?.toUpperCase()}</h1>
      
      <div className="application-info">
        <p className="info-text">$30,000 investment.</p>
        <p className="info-text">For students, dropouts, & grads.</p>
        <p className='info-text'>We fund new teams every 3 months.</p>
        <p className="info-text">Our next cycle will be {batchData?.['Batch Name']}.</p>
        {/* <p className="info-text">We plan to fund {+(batchData?.['Target Batch Size'])}-{+(batchData?.['Target Batch Size'])+1} teams this cycle.</p> */}
        
        
        {isPastDeadline ? (
          <>
            <p className="info-text">Our original app deadline passed & we are mid-review.</p>
            <p className="info-text">However, we will review late apps.</p>
            <p className="info-text">We just ask for your patience while we review.</p>
            <p className="info-text">We also fund new teams every 3 months.</p>
          </>
        ) : (
          <>
            <p className="info-text">App Deadline - {batchData?.['App Deadline']}</p>
            <p className="info-text">App Response - {batchData?.['App Response']}</p>
          </>
        )}
        
        <FundingTimeline />
      </div>

      <hr className="light-divider" />

      <form 
        onSubmit={handleSubmit} 
        className="application-form"
        onKeyDown={handleKeyDown}
      >
        <h2>Application</h2>
        <div className="form-group">
          <label htmlFor="projectName" className="info-text required">
            Project Name
          </label>
          <input
            type="text"
            id="projectName"
            value={formData.projectName}
            onChange={handleChange}
            className="form-input"
            required
            maxLength={CHAR_LIMITS.PROJECT_NAME}
            placeholder="what do you want to call this?"
          />
          {(() => {
            const { remaining, className } = getRemainingChars(formData.projectName, CHAR_LIMITS.PROJECT_NAME);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>

        <div className="form-group">
          <label htmlFor="oneLinerDescription" className="info-text required">
            One-Liner Description
          </label>
          <textarea
            id="oneLinerDescription"
            value={formData.oneLinerDescription || ''}
            onChange={handleChange}
            className="form-input"
            required
            maxLength={60}
            placeholder="summarize in less than 60 characters"
            style={{ minHeight: '60px' }}
          ></textarea>
          {(() => {
            const { remaining, className } = getRemainingChars(formData.oneLinerDescription || '', 60);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>

        <hr className="light-divider" />

        <h2 className="form-section-title">Founders</h2>
        {formData.founders.map((founder, index) => (
          <div key={index} className="form-section">
            <div className="form-group-title-section">
              <h3 className="form-subtitle">Founder #{index + 1}</h3>
            </div>
            <div className="form-group">
              <label htmlFor={`founder-firstName-${index}`} className={`info-text ${index === 0 ? 'required' : ''}`}>
                First Name
              </label>
              <input
                type="text"
                id={`founder-firstName-${index}`}
                value={founder.firstName}
                onChange={(e) => handleChange(e, index)}
                className="form-input"
                required={index === 0}
                maxLength={CHAR_LIMITS.FOUNDER_NAME}
                placeholder="..."
              />
              {(() => {
                const { remaining, className } = getRemainingChars(founder.firstName, CHAR_LIMITS.FOUNDER_NAME);
                return (
                  <span className={className}>
                    {remaining} characters remaining
                  </span>
                );
              })()}
            </div>

            <div className="form-group">
              <label htmlFor={`founder-lastName-${index}`} className={`info-text ${index === 0 ? 'required' : ''}`}>
                Last Name
              </label>
              <input
                type="text"
                id={`founder-lastName-${index}`}
                value={founder.lastName}
                onChange={(e) => handleChange(e, index)}
                className="form-input"
                required={index === 0}
                maxLength={CHAR_LIMITS.FOUNDER_NAME}
                placeholder="..."
              />
              {(() => {
                const { remaining, className } = getRemainingChars(founder.lastName, CHAR_LIMITS.FOUNDER_NAME);
                return (
                  <span className={className}>
                    {remaining} characters remaining
                  </span>
                );
              })()}
            </div>

            <div className="form-group">
              <label htmlFor={`founder-email-${index}`} className={`info-text ${index === 0 ? 'required' : ''}`}>
                Email
              </label>
              <input
                type="email"
                id={`founder-email-${index}`}
                value={founder.email}
                onChange={(e) => handleChange(e, index)}
                className="form-input"
                required={index === 0}
                maxLength={CHAR_LIMITS.FOUNDER_EMAIL}
                placeholder="so we can contact you"
              />
              {(() => {
                const { remaining, className } = getRemainingChars(founder.email, CHAR_LIMITS.FOUNDER_EMAIL);
                return (
                  <span className={className}>
                    {remaining} characters remaining
                  </span>
                );
              })()}
            </div>

            <div className="form-group">
              <label htmlFor={`founder-age-${index}`} className={`info-text ${index === 0 ? 'required' : ''}`}>
                Age
              </label>
              <input
                type="text"
                id={`founder-age-${index}`}
                value={founder.age || ''}
                onChange={(e) => {
                  // Only allow 2-digit numbers or empty for non-primary founders
                  const value = e.target.value;
                  if (value === '' || (/^\d{1,2}$/.test(value) && parseInt(value) > 0)) {
                    handleChange({
                      target: {
                        id: `founder-age-${index}`,
                        value
                      }
                    }, index);
                  }
                }}
                className="form-input"
                placeholder="..."
                maxLength={2}
                required={index === 0}
                // Only validate primary founder
                pattern={index === 0 ? "^[1-9][0-9]?$" : "^$|^[1-9][0-9]?$"}
              />
            </div>

            <div className="form-group">
              <label htmlFor={`founder-role-${index}`} className={`info-text ${index === 0 ? 'required' : ''}`}>Role</label>
              <div className="role-buttons">
                {(!founder.role || !isMobile || founder.role === "Technical") && (
                  <div style={{ display: 'flex', alignItems: 'center', height: '60px' }}>
                    <button
                      type="button"
                      className={`role-btn ${founder.role === "Technical" ? "selected" : ""}`}
                      onClick={() => handleRoleChange(index, "Technical")}
                    >
                      Technical
                    </button>
                    {isMobile && founder.role === "Technical" && (
                      <button
                        type="button"
                        onClick={() => handleResetRole(index)}
                        className="role-reset-btn"
                      >
                        Reset
                      </button>
                    )}
                  </div>
                )}
                {(!founder.role || !isMobile || founder.role === "Sales/Growth") && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button
                      type="button"
                      className={`role-btn ${founder.role === "Sales/Growth" ? "selected" : ""}`}
                      onClick={() => handleRoleChange(index, "Sales/Growth")}
                    >
                      Sales/Growth
                    </button>
                    {isMobile && founder.role === "Sales/Growth" && (
                      <button
                        type="button"
                        onClick={() => handleResetRole(index)}
                        className="role-reset-btn"
                      >
                        Reset
                      </button>
                    )}
                  </div>
                )}
                {(!founder.role || !isMobile || founder.role === "Both") && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button
                      type="button"
                      className={`role-btn ${founder.role === "Both" ? "selected" : ""}`}
                      onClick={() => handleRoleChange(index, "Both")}
                    >
                      Both
                    </button>
                    {isMobile && founder.role === "Both" && (
                      <button
                        type="button"
                        onClick={() => handleResetRole(index)}
                        className="role-reset-btn"
                      >
                        Reset
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor={`founder-links-${index}`} className="info-text">
                LinkedIn Link
              </label>
              <input
                type="text"
                id={`founder-links-${index}`}
                value={founder.links}
                onChange={(e) => handleChange(e, index)}
                className="form-input"
                maxLength={CHAR_LIMITS.FOUNDER_LINKS}
                placeholder="hoping to verify you are human :)"
              />
              {(() => {
                const { remaining, className } = getRemainingChars(founder.links, CHAR_LIMITS.FOUNDER_LINKS);
                return (
                  <span className={className}>
                    {remaining} characters remaining
                  </span>
                );
              })()}
            </div>
            
            {index > 0 && (
              <div style={{ display: 'flex', gap: '10px', marginTop: '1rem' }}>
                <button 
                  type="button" 
                  onClick={() => removeFounder(index)}
                  className="remove-founder-btn"
                  style={{ margin: 0 }}
                >
                  Remove Founder
                </button>
              </div>
            )}
          </div>
        ))}

        {formData.founders.length < 4 && (
          <button 
            type="button" 
            onClick={addFounder}
            className="add-founder-btn"
          >
            Add Another Founder
          </button>
        )}

        <hr className="light-divider" />


        <h2 className="form-section-title">What Are You Building?</h2>
        <div className="form-group">
          <label htmlFor="problem" className="info-text required">
            What Problem Are You Solving? (under 100 words)
          </label>
          <textarea
            id="problem"
            value={formData.problem}
            onChange={handleChange}
            className="form-input"
            maxLength={CHAR_LIMITS.PROBLEM}
            required
            placeholder="we look for teams solving problems"
          />
          {(() => {
            const { remaining, className } = getRemainingChars(formData.problem, CHAR_LIMITS.PROBLEM);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>

        <div className="form-group">
          <label htmlFor="solution" className="info-text required">
            How Will You Solve This Problem? (under 100 words)
          </label>
          <textarea
            id="solution"
            value={formData.solution}
            onChange={handleChange}
            className="form-input"
            maxLength={CHAR_LIMITS.SOLUTION}
            required
            placeholder="what is your solution?"
          />
          {(() => {
            const { remaining, className } = getRemainingChars(formData.solution, CHAR_LIMITS.SOLUTION);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>

        <div className="form-group">
          <label htmlFor="targetCustomer" className="info-text required">
            Who Is Your Target Customer? (under 100 words)
          </label>
          <textarea
            id="targetCustomer"
            value={formData.targetCustomer}
            onChange={handleChange}
            className="form-input"
            maxLength={CHAR_LIMITS.TARGET_CUSTOMER}
            required
            placeholder="ideally someone badly wants a solution to this problem"
          />
          {(() => {
            const { remaining, className } = getRemainingChars(formData.targetCustomer, CHAR_LIMITS.TARGET_CUSTOMER);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>

        <div className="form-group">
          <label htmlFor="uniqueInsight" className="info-text required">
            What Is Your Unique Insight To Build This Product? (under 100 words)
          </label>
          <textarea
            id="uniqueInsight"
            value={formData.uniqueInsight}
            onChange={handleChange}
            className="form-input"
            maxLength={CHAR_LIMITS.UNIQUE_INSIGHT}
            required
            placeholder="what do you see that others don't?"
          />
          {(() => {
            const { remaining, className } = getRemainingChars(formData.uniqueInsight, CHAR_LIMITS.UNIQUE_INSIGHT);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>

        <div className="form-group">
          <label htmlFor="whyEquipped" className="info-text required">
            Why Are You Equipped To Do This? (under 100 words)
          </label>
          <textarea
            id="whyEquipped"
            value={formData.whyEquipped}
            onChange={handleChange}
            className="form-input"
            maxLength={CHAR_LIMITS.WHY_EQUIPPED}
            required
            placeholder="building is hard. Why are you the right team to do this?"
          />
          {(() => {
            const { remaining, className } = getRemainingChars(formData.whyEquipped, CHAR_LIMITS.WHY_EQUIPPED);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>

        <div className="form-group">
          <label htmlFor="whyDoThis" className="info-text required">
            Why Do You Want To Do This? (under 100 words)
          </label>
          <textarea
            id="whyDoThis"
            value={formData.whyDoThis}
            onChange={handleChange}
            className="form-input"
            maxLength={CHAR_LIMITS.WHY_DO_THIS}
            required
            placeholder="out of all the things you could be doing, why this?"
          />
          {(() => {
            const { remaining, className } = getRemainingChars(formData.whyDoThis, CHAR_LIMITS.WHY_DO_THIS);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>

        <hr className="light-divider" />

        <h2 className="form-section-title">Project Status</h2>

        <div className="form-group">
          <label htmlFor="workingDuration" className="info-text required">
            How Long Have You Been Working This?
          </label>
          <textarea
            id="workingDuration"
            value={formData.workingDuration || ''}
            onChange={handleChange}
            className="form-input"
            maxLength={500}
            required
            placeholder="no wrong answer. 40%+ of LeapYear teams were idea stage"
            style={{ minHeight: '60px' }}
          ></textarea>
          {(() => {
            const { remaining, className } = getRemainingChars(formData.workingDuration || '', 500);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>

        <div className="form-group">
          <label htmlFor="incorporated" className="info-text">
            Have You Incorporated as a C-Corp Yet?
          </label>
          <div className="yes-no-buttons" style={{
            display: 'flex',
            gap: '10px',
            marginTop: '10px'
          }}>
            <button
              type="button"
              className={`role-btn ${formData.incorporated === true ? "selected" : ""}`}
              onClick={() => handleChange({
                target: {
                  id: 'incorporated',
                  value: true
                }
              })}
            >
              Yes
            </button>
            <button
              type="button"
              className={`role-btn ${formData.incorporated === false ? "selected" : ""}`}
              onClick={() => handleChange({
                target: {
                  id: 'incorporated',
                  value: false
                }
              })}
            >
              No
            </button>
          </div>
        </div>

        {formData.incorporated === true && (
          <div className="form-group">
            <label htmlFor="corpName" className="info-text required">
              What is your C-Corp Name?
            </label>
            <input
              type="text"
              id="corpName"
              value={formData.corpName || ''}
              onChange={handleChange}
              className="form-input"
              maxLength={40}
              required={formData.incorporated === true}
              placeholder="e.g. Acme Inc."
            />
            {(() => {
              const { remaining, className } = getRemainingChars(formData.corpName || '', 40);
              return (
                <span className={className}>
                  {remaining} characters remaining
                </span>
              );
            })()}
          </div>
        )}

        <div className="form-group">
          <label htmlFor="takenInvestment" className="info-text">
            Have You Taken Investment?
          </label>
          <div className="yes-no-buttons" style={{
            display: 'flex',
            gap: '10px',
            marginTop: '10px'
          }}>
            <button
              type="button"
              className={`role-btn ${formData.takenInvestment === true ? "selected" : ""}`}
              onClick={() => handleChange({
                target: {
                  id: 'takenInvestment',
                  value: true
                }
              })}
            >
              Yes
            </button>
            <button
              type="button"
              className={`role-btn ${formData.takenInvestment === false ? "selected" : ""}`}
              onClick={() => handleChange({
                target: {
                  id: 'takenInvestment',
                  value: false
                }
              })}
            >
              No
            </button>
          </div>
        </div>

        {formData.takenInvestment === true && (
          <div className="form-group">
            <label htmlFor="investmentAmount" className="info-text required">
              How Much Investment Have You Taken?
            </label>
            <input
              type="text"
              id="investmentAmount"
              value={formData.investmentAmount || ''}
              onChange={handleChange}
              className="form-input"
              maxLength={30}
              required={formData.takenInvestment === true}
              placeholder="e.g. $50,000"
            />
            {(() => {
              const { remaining, className } = getRemainingChars(formData.investmentAmount || '', 30);
              return (
                <span className={className}>
                  {remaining} characters remaining
                </span>
              );
            })()}
          </div>
        )}

        <div className="form-group">
          <label htmlFor="hasCustomers" className="info-text">
            Do You Have Customers?
          </label>
          <div className="yes-no-buttons" style={{
            display: 'flex',
            gap: '10px',
            marginTop: '10px'
          }}>
            <button
              type="button"
              className={`role-btn ${formData.hasCustomers === true ? "selected" : ""}`}
              onClick={() => handleChange({
                target: {
                  id: 'hasCustomers',
                  value: true
                }
              })}
            >
              Yes
            </button>
            <button
              type="button"
              className={`role-btn ${formData.hasCustomers === false ? "selected" : ""}`}
              onClick={() => handleChange({
                target: {
                  id: 'hasCustomers',
                  value: false
                }
              })}
            >
              No
            </button>
          </div>
        </div>

        {formData.hasCustomers === true && (
          <>
            <div className="form-group">
              <label htmlFor="customerCount" className="info-text required">
                How Many Customers Do You Have?
              </label>
              <input
                type="text"
                id="customerCount"
                value={formData.customerCount || ''}
                onChange={handleChange}
                className="form-input"
                maxLength={20}
                required={formData.hasCustomers === true}
                placeholder="e.g. 10 customers"
              />
              {(() => {
                const { remaining, className } = getRemainingChars(formData.customerCount || '', 20);
                return (
                  <span className={className}>
                    {remaining} characters remaining
                  </span>
                );
              })()}
            </div>

            <div className="form-group">
              <label htmlFor="hasRevenue" className="info-text">
                Do You Have Revenue?
              </label>
              <div className="yes-no-buttons" style={{
                display: 'flex',
                gap: '10px',
                marginTop: '10px'
              }}>
                <button
                  type="button"
                  className={`role-btn ${formData.hasRevenue === true ? "selected" : ""}`}
                  onClick={() => handleChange({
                    target: {
                      id: 'hasRevenue',
                      value: true
                    }
                  })}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={`role-btn ${formData.hasRevenue === false ? "selected" : ""}`}
                  onClick={() => handleChange({
                    target: {
                      id: 'hasRevenue',
                      value: false
                    }
                  })}
                >
                  No
                </button>
              </div>
            </div>

            {formData.hasRevenue === true && (
              <div className="form-group">
                <label htmlFor="revenueAmount" className="info-text required">
                  How Much Revenue?
                </label>
                <input
                  type="text"
                  id="revenueAmount"
                  value={formData.revenueAmount || ''}
                  onChange={handleChange}
                  className="form-input"
                  maxLength={30}
                  required={formData.hasRevenue === true}
                  placeholder="e.g. $5,000 per month"
                />
                {(() => {
                  const { remaining, className } = getRemainingChars(formData.revenueAmount || '', 30);
                  return (
                    <span className={className}>
                      {remaining} characters remaining
                    </span>
                  );
                })()}
              </div>
            )}
          </>
        )}

        <hr className="light-divider" />

        <h2 className="form-section-title">Bonus Stuff</h2>

        <div className="form-group">
          <label htmlFor="relevantLinks" className="info-text">
            Feel Free To Link Anything Relevant
          </label>
          <input
            type="text"
            id="relevantLinks"
            value={formData.relevantLinks}
            onChange={handleChange}
            className="form-input"
            maxLength={CHAR_LIMITS.RELEVANT_LINKS}
            placeholder="any links (website, demos, etc.)"
          />
          {(() => {
            const { remaining, className } = getRemainingChars(formData.relevantLinks, CHAR_LIMITS.RELEVANT_LINKS);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>
        
        <div className="form-group">
          <label htmlFor="fullTimeCommitment" className="info-text">
            Are you full-time on this? Do you want to be? If so, when?
          </label>
          <textarea
            id="fullTimeCommitment"
            value={formData.fullTimeCommitment || ''}
            onChange={handleChange}
            className="form-input"
            maxLength={1000}
            placeholder="the future is yours"
          />
          {(() => {
            const { remaining, className } = getRemainingChars(formData.fullTimeCommitment || '', 1000);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>

        <div className="form-group">
          <label htmlFor="howHeard" className="info-text">
            How Did You Hear About LeapYear?
          </label>
          <textarea
            id="howHeard"
            value={formData.howHeard}
            onChange={handleChange}
            className="form-input"
            maxLength={CHAR_LIMITS.HOW_HEARD}
            placeholder="curious how you found us"
          />
          {(() => {
            const { remaining, className } = getRemainingChars(formData.howHeard, CHAR_LIMITS.HOW_HEARD);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>

        <div className="form-group">
          <label htmlFor="additionalInfo" className="info-text">
            Anything Else To Know?
          </label>
          <textarea
            id="additionalInfo"
            value={formData.additionalInfo}
            onChange={handleChange}
            className="form-input"
            maxLength={CHAR_LIMITS.ADDITIONAL_INFO}
            placeholder="tell us whatever"
          />
          {(() => {
            const { remaining, className } = getRemainingChars(formData.additionalInfo, CHAR_LIMITS.ADDITIONAL_INFO);
            return (
              <span className={className}>
                {remaining} characters remaining
              </span>
            );
          })()}
        </div>

        {error && (
          <div className="error-submit-container">
            <h1 className="page-title">Ah Sh*t...</h1>
            <p className="info-text">I'm sorry. We probably wrote some bad code.</p>
            <p className="info-text">If you got this. Feel free to copy your application summary and email us at build@takeleapyear.com.</p>
            <p className="info-text">We will review & get back to you soon.</p>
            <p className="info-text">Sincerely, the LeapYear Team</p>
            <button 
              type="button"
              className="try-again-btn"
              onClick={() => setError(null)}
            >
              Dismiss Error
            </button>
          </div>
        )}

        <button type="submit" className="submit-btn">
          Submit Application
        </button>
      </form>
    </div>
  );
}

export default Apply;
