import React, { useState, useEffect } from 'react';

function RotatingButton({ 
  href, 
  className, 
  role, 
  ariaLabel, 
  apiData, 
  isLoading, 
  rotatingTexts = ["BE AMBITIOUS..."] 
}) {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [rotationCount, setRotationCount] = useState(0);

  // Handle text rotation
  useEffect(() => {
    const rotationInterval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % rotatingTexts.length;

        // If we're going back to the first text, increment rotation count
        if (nextIndex === 0) {
          setRotationCount((prev) => prev + 1);
        }

        return nextIndex;
      });
    }, 1000); // Rotate every 1 second

    return () => clearInterval(rotationInterval);
  }, [rotatingTexts.length]);

  // Determine what text to display
  const displayText = () => {
    // On third rotation (or later) and if API data is available, show API data
    if (rotationCount >= 1 && !isLoading && apiData) {
      return apiData;
    }

    // Otherwise show rotating text
    return rotatingTexts[currentTextIndex];
  };

  return (
    <a 
      href={href} 
      className={className} 
      role={role} 
      aria-label={ariaLabel || displayText()}
    >
      <span>
        <span 
          key={displayText()} // Key helps React identify when text changes
          style={{
            display: 'block',
            animation: 'fadeInOut 0.3s ease-in-out',
            fontSize: '1.15rem'
          }}
        >
          {displayText()}
        </span>
      </span>
    </a>
  );
}

export default RotatingButton; 