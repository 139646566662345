import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="container" style={{ textAlign: 'center', padding: '4rem 1rem' }}>
      <h1 className="page-title">404</h1>
      <h2 style={{ 
        fontSize: '1.5rem', 
        color: 'rgba(255, 255, 255, 0.8)',
        marginBottom: '2rem' 
      }}>
        Page Not Found
      </h2>
      <p style={{ 
        fontSize: '1.1rem',
        color: 'rgba(255, 255, 255, 0.6)',
        marginBottom: '2rem'
      }}>
        The page you're looking for doesn't exist or has been moved.
      </p>
      <Link 
        to="/" 
        style={{
          display: 'inline-block',
          padding: '0.75rem 1.5rem',
          backgroundColor: 'white',
          color: 'black',
          textDecoration: 'none',
          borderRadius: '4px',
          fontSize: '1.1rem',
          transition: 'opacity 0.2s ease'
        }}
        onMouseOver={e => e.target.style.opacity = '0.8'}
        onMouseOut={e => e.target.style.opacity = '1'}
      >
        Return Home
      </Link>
    </div>
  );
}

export default NotFound; 