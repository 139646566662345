import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isLocalhost = window.location.hostname === 'localhost';

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // Prevent scrolling when menu is open
    document.body.style.overflow = !isMenuOpen ? 'hidden' : 'auto';
  };

  return (
    <header>
      <nav className="nav-container">
        {isLocalhost && (
          <button 
            className="nav-button" 
            onClick={() => window.location.href="/internal-tools/applications"} 
            style={{ position: 'absolute'}}
            aria-label="Internal Tools"
          >
            INTERNAL
          </button>
        )}
        <div to="/" className="logo bold">
          
        </div>
        
        <div className="desktop-menu">
          <div className="menu-links">
            <Link to="/">HOME</Link>
            <Link to="/apply">APPLY</Link>
            <Link to="/investments">INVESTMENTS</Link>
            <Link to="/people">PEOPLE</Link>
            <Link to="/scout">SCOUT</Link>
            <Link to="/faq">FAQ</Link>
          </div>
        </div>

        <div 
          className={`hamburger ${isMenuOpen ? 'active' : ''}`}
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <span></span>
          <span style={{ width: '1.0rem', marginLeft: '1.0rem' }}></span>
          <span></span>
        </div>

        <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
          <div className="menu-links">
            <Link to="/" onClick={toggleMenu}>HOME</Link>
            <Link to="/apply" onClick={toggleMenu}>APPLY</Link>
            <Link to="/investments" onClick={toggleMenu}>INVESTMENTS</Link>
            <Link to="/people" onClick={toggleMenu}>PEOPLE</Link>
            <Link to="/scout" onClick={toggleMenu}>SCOUT</Link>
            <Link to="/faq" onClick={toggleMenu}>FAQ</Link>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;