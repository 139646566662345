import React, { useState, useEffect } from 'react';
import { fetchApi } from '../../utils/api';

function FAQ() {
  const [websiteInputs, setWebsiteInputs] = useState({});

  useEffect(() => {
    const fetchWebsiteInputs = async () => {
      try {
        const response = await fetchApi('/api/public/website-inputs/', {
          method: 'GET',
          params: {
            table: 'website_inputs',
            name: 'Next Batch'
          }
        });
        
        if (response.data && response.data.length > 0) {
          setWebsiteInputs(response.data[0].input_json);
        }
      } catch (error) {
        console.error('Error fetching website inputs:', error);
      }
    };

    fetchWebsiteInputs();
  }, []);

  return (
    <main className="container">
      <h1 className="page-title">FAQs</h1>
      
      <section className="faq-container">
        <h2 className="faq-title">What Is LeapYear?</h2>
        <p>LeapYear is a bet</p>
        <p>A bet that if the young people go build for 1 year, great things will happen</p>
        <p>We operate as a mission-driven seed fund (or first-believer fund)</p>
        <p>We challenge builders to take 1 year and build something ambitious</p>
        <p>We have no required programming and will invest anywhere in anything</p>
        <p>We look for relentless builders, ready to do the best work of their lives</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">Who do you invest in?</h2>
        <p>We are open to all but primarily look for student teams, early in their ideas</p>
        <p>Student teams means you recently graduated, dropped out, or still in school</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">What stage do you invest at?</h2>
        <p>Day 1. That's why we are willing to back teams still in school</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">How does it work?</h2>
        <p>Teams apply. We fund a few. They build. We offer help when they want it</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">Is this a startup accelerator?</h2>
        <p>No. We aren't trying to "accelerate startups in weeks" </p>
        <p>LeapYear is a 1-year commit. Not 3 months</p>
        <p>You can build anywhere. We have no required programming</p>
        <p>We invest and try to help when you want it</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">Is it all virtual?</h2>
        <p>Yes. We like meeting in person when we can but LeapYear is remote</p>
        <p>This allows us to fund teams anywhere</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">How Can We Apply?</h2>
        <p>Apply online. We fund new teams 4x/year (Jan, Apr, July, Oct)</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">Is it really 1 full year?</h2>
        <p>Teams need to commit to building for 1 year. Great things take time</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">What happens after 1 year?</h2>
        <p>Ideally, your work is scaling. Maybe it doesn't but you learn a lot</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">How is this different than existing options?</h2>
        <p>Not an accelerator. Commit for 1 year</p>
        <p>We don't make you move. Build anywhere</p>
        <p>If you are still in school, we don't make you drop out</p>
        <p>We only fund 6-7 teams every 3 months. 25-30 teams/year</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">Why don't you make students drop out?</h2>
        <p>Conviction doesn't come overnight. It starts as an idea and grows</p>
        <p>If you commit to 1 year, we trust you will make the time to build</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">Do I need to be in school?</h2>
        <p>No. We review all applications. We've funded many grads + dropouts</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">How does the investment work?</h2>
        <p>We invest {websiteInputs?.['Funding']} for {websiteInputs?.['Equity']} equity via a post-money SAFE</p>
        <p>It's intentionally a small investment with low dilution</p>
        <p>We think its enough to help you get started and to the next stage</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">We've already taken funding. Can we still apply?</h2>
        <p>Yes. If it works, we can solve details</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">Will you really fund just a team with an idea?</h2>
        <p>Yes. 40%+ of teams we've funded have been idea-stage</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">What if our team has been building for a while?</h2>
        <p>Yes apply. We look for relentless teams</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">Are solo founders eligible?</h2>
        <p>Yes but we strongly encourage having co-founders</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">What if we have 2 ideas? How should we apply?</h2>
        <p>Just apply twice with different ideas</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">Are we a non-profit?</h2>
        <p>No. For-profit venture fund. It aligns long-term incentives</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">If we get more funding while on LeapYear, can we take it?</h2>
        <p>Yes. We can also intro you to investors if you want it</p>
      </section>

      <section className="faq-container">
        <h2 className="faq-title">What if we get into other great programs (e.g. YC) on LeapYear?</h2>
        <p>LY only takes {websiteInputs?.['Equity']} equity and has no required programming</p>
        <p>We do this to give teams flexibility to pursue any & all growth paths</p>
        <p>So yes, if you are on LY and get into YC or Neo, you can definitely go</p>
        <p>We will still be a text away when you need us</p>
      </section>
    </main>
  );
}

export default FAQ;