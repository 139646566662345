import React, {} from 'react';

// Pre-defined arrays with different orders
const companyData = {
    DoorDash: {
      logo: "https://i.pinimg.com/originals/45/43/7d/45437d722655e2d1e8eae551dad8f29a.png"
    },
    Uber: {
      logo: "https://www.pngall.com/wp-content/uploads/4/Uber-Logo-PNG-Picture.png"
    },
    Stripe: {
      logo: "https://voyagecontrol.com/hs-fs/hubfs/14stripe.png?width=600&height=600&name=14stripe.png"
    },
    Carta: {
      logo: "https://secondarylink.com/images/news-search-image/CartaLogo_White_1702917818.png"
    },
    Fanatics: {
      logo: "https://companieslogo.com/img/orig/fanatics-d275f8e4.png?t=1720244494"
    },
    Groupon: {
      logo: "https://companieslogo.com/img/orig/GRPN-0451ecc1.png?t=1720244492"
    },
    Dropbox: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Dropbox_Icon.svg/2202px-Dropbox_Icon.svg.png"
    },
    Rappi: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Rappi_logo.svg/1200px-Rappi_logo.svg.png"
    },
    OpenDoor: {
      logo: "https://logos-download.com/wp-content/uploads/2022/11/Opendoor_Logo.svg"
    },
    Google: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/800px-Google_%22G%22_logo.svg.png"
    },
    "Flatiron Health": {
      logo: "https://www.builtinnyc.com/sites/www.builtinnyc.com/files/2024-05/Vertical%20logo.png"
    },
    Braintree: {
      logo: "https://store.smith-consulting.com/Portals/0/RazorCart/Images/92_1.png"
    },
    Tegus: {
      logo: "https://www.trustradius.com/vendor-logos/9u/Df/8W2F4RCMSKE5-180x180.PNG"
    },
    Project44: {
      logo: "https://logo.clearbit.com/http://project44.com"
    }
  };
  
  const labelArrays = {
    first: [
    "DoorDash",
    "Uber",
    "Stripe",
    "Groupon",
    "Rappi",
    "OpenDoor",
    "Braintree"
    ],
    second: [
    "Dropbox",
    "Fanatics",
    "Carta",
    "Google",
    "Flatiron Health",
    "Tegus",
    "Project44"
    ]
  };
  
  const PeopleScroll = ({ speed = '30s', reverse = false, variant = 'first' }) => {
    const labels = labelArrays[variant];
    const totalLabels = labels.length;
  
    return (
      <div className="logo-loop">
        <div 
          className="logo-track"
          style={{
            '--total-items': totalLabels,
            '--animation-duration': speed,
            'flexDirection': reverse ? 'row-reverse' : 'row'
          }}
        >
          {[...labels, ...labels].map((label, index) => (
            <span 
              key={`label-${index}`}
              className="label"
            >
              {companyData[label]?.logo && (
                <img 
                  src={companyData[label].logo} 
                  alt={`${label} logo`}
                  className="school-logo"
                />
              )}
              {label}
            </span>
          ))}
        </div>
      </div>
    );
  }; 
       

function PeopleIntro() {

  return (
    <main>
          <section className="content-section">
            <div className="list-grid">
              <div className="list-item bold no-bullet">Support.</div>
            </div>
            <p className="list-grid-text">We are run + funded by a network of builders here to help and a text / phone call away.</p>
          </section>
          <section className="content-section">
            <PeopleScroll speed="90s" variant="first" />
            <PeopleScroll speed="90s" variant="second" />
          </section>
    </main>
  );
}

export default PeopleIntro;