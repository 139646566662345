import React, { useEffect } from 'react';

function PostApply() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="container">
        <div className="post-apply-container">
            <h1 className="page-title">Thank you!</h1>
            <h2>We have your application</h2>
            <p className="info-text">Seriously, we appreciate you coming to this site, resonating with the LeapYear mission and taking the time to apply. We are humbled by every builder we meet.</p>
            <p className="info-text">We fund new teams every 3 months.</p>
            <p className="info-text">We will review your application & get back to you soon.</p>
            <p className="info-text">Sincerely,</p>
            <p className="info-text">the LeapYear Team</p>
        </div>
    </main>
  );
}

export default PostApply;