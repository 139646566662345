import { createClient } from '@supabase/supabase-js';

// Create a Supabase client for the frontend
const supabaseClient = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

/**
 * Get a public URL for an image in the Supabase storage bucket
 * @param {string} imagePath - Path to the image in the bucket (e.g., 'people/hank-couture.jpg')
 * @returns {string} - Public URL for the image
 */
export const getImageUrl = (imagePath) => {
  if (!imagePath) return '';
  
  // If it's already a full URL, return it as is
  if (imagePath.startsWith('http')) {
    return imagePath;
  }
  
  // Otherwise, construct the URL from Supabase storage
  const { data } = supabaseClient.storage
    .from('images')
    .getPublicUrl(imagePath);
    
  return data?.publicUrl || '';
};

/**
 * Upload an image to Supabase storage
 * @param {File} file - The file to upload
 * @param {string} path - Path in the bucket (e.g., 'people/hank-couture.jpg')
 * @returns {Promise<string>} - Public URL of the uploaded image
 */
export const uploadImage = async (file, path) => {
  const { error } = await supabaseClient.storage
    .from('images')
    .upload(path, file, {
      cacheControl: '3600',
      upsert: true
    });
    
  if (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
  
  return getImageUrl(path);
}; 