import React from 'react';
import './App.css';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ErrorBoundary from './components/errors/error-boundary.js';
import ProtectedRoute from './components/common/protected-route.js';

import Header from './components/common/header.js';
import Intro from './components/pages/intro.js';
import MainFooter from './components/common/main-footer.js';
import LightFooter from './components/common/light-footer.js';

import Apply from './components/forms/apply.js';
import FAQ from './components/pages/faq.js';
import Investments from './components/pages/investments.js';
import People from './components/pages/people.js';
import PostApply from './components/forms/post-apply.js';
import NextBatch from './components/common/first-believer-fund.js';
import ErrorPage from './components/errors/error-page.js';
import Scout from './components/forms/scout.js';
import ErrorSubmit from './components/errors/error-submit.js';
import LoadingModalDemo from './components/bonus/loading-modal-demo.js';
import PointInTime from './components/pages/point-in-time.js';

// Conditionally import internal tools components
const InternalLayout = process.env.NODE_ENV === 'development'
  ? React.lazy(() => import('./components/internal-tools/utils/internal-layout.js'))
  : null;

const AppSummary = process.env.NODE_ENV === 'development'
  ? React.lazy(() => import('./components/internal-tools/pages/app-summary.js'))
  : null;

const Founders = process.env.NODE_ENV === 'development'
  ? React.lazy(() => import('./components/internal-tools/pages/founders.js'))
  : null;

const Investors = process.env.NODE_ENV === 'development'
  ? React.lazy(() => import('./components/internal-tools/pages/investors.js'))
  : null;

const EmailPreview = process.env.NODE_ENV === 'development'
  ? React.lazy(() => import('./components/internal-tools/pages/email-preview.js'))
  : null;

const Applications = process.env.NODE_ENV === 'development'
  ? React.lazy(() => import('./components/internal-tools/pages/applications.js'))
  : null;

const WebsiteInputs = process.env.NODE_ENV === 'development'
  ? React.lazy(() => import('./components/internal-tools/pages/website-inputs.js'))
  : null;

const InvestmentsInputs = process.env.NODE_ENV === 'development'
  ? React.lazy(() => import('./components/internal-tools/pages/investments_inputs.js'))
  : null;

const TailwindTest = process.env.NODE_ENV === 'development'
  ? React.lazy(() => import('./components/internal-tools/pages/TailwindTest.js'))
  : null;

const AppCycles = process.env.NODE_ENV === 'development'
  ? React.lazy(() => import('./components/internal-tools/pages/app_cycles.js'))
  : null;

const OpenAITest = process.env.NODE_ENV === 'development'
  ? React.lazy(() => import('./components/internal-tools/pages/openai_test.js'))
  : null;

const Schools = process.env.NODE_ENV === 'development'
  ? React.lazy(() => import('./components/internal-tools/pages/schools.js'))
  : null;

// Create a wrapper component to handle the conditional footer rendering
function AppContent() {
  const location = useLocation();
  const lightFooterPaths = ['/post-apply', '/apply'];
  const noFooterPaths = [
    '/error',
    ...(process.env.NODE_ENV === 'development' 
      ? ['/internal-tools/email-preview', '/internal-tools/applications'] 
      : []
    )
  ];

  const getFooter = () => {
    if (noFooterPaths.includes(location.pathname)) {
      return null;
    }
    if (lightFooterPaths.includes(location.pathname)) {
      return <LightFooter />;
    }
    return <MainFooter />;
  };

  const InternalToolsWrapper = ({ children }) => {
    if (process.env.NODE_ENV !== 'development') {
      return <ErrorPage />;
    }
    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <ProtectedRoute>
          <React.Suspense fallback={<div>Loading...</div>}>
            {InternalLayout && <InternalLayout>{children}</InternalLayout>}
          </React.Suspense>
        </ProtectedRoute>
      </React.Suspense>
    );
  };

  return (
    <ErrorBoundary>
      <>
        <Header />
        <Routes>
          <Route path="/" element={<Intro />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/investments" element={<Investments />} />
          <Route path="/people" element={<People />} />
          <Route path="/scout" element={<Scout />} />
          <Route path="/post-apply" element={<PostApply />} />
          <Route path="/next-batch" element={<NextBatch />} />
          <Route path="/error-submit" element={<ErrorSubmit />} />
          <Route path="/loading-modal-demo" element={<LoadingModalDemo />} />
          <Route path="/point-in-time" element={<PointInTime />} />
          
          {/* Internal Tools Routes - Only rendered in development */}
          {process.env.NODE_ENV === 'development' && (
            <>
              <Route 
                path="/internal-tools/email-preview" 
                element={
                  <InternalToolsWrapper>
                    {EmailPreview && <EmailPreview />}
                  </InternalToolsWrapper>
                } 
              />
              <Route 
                path="/internal-tools/applications" 
                element={
                  <InternalToolsWrapper>
                    {Applications && <Applications />}
                  </InternalToolsWrapper>
                } 
              />
              <Route 
                path="/internal-tools/founders" 
                element={
                  <InternalToolsWrapper>
                    {Founders && <Founders />}
                  </InternalToolsWrapper>
                } 
              />
              <Route 
                path="/internal-tools/investors" 
                element={
                  <InternalToolsWrapper>
                    {Investors && <Investors />}
                  </InternalToolsWrapper>
                } 
              />
              <Route 
                path="/internal-tools/website-inputs" 
                element={
                  <InternalToolsWrapper>
                    {WebsiteInputs && <WebsiteInputs />}
                  </InternalToolsWrapper>
                } 
              />
              <Route 
                path="/internal-tools/investments" 
                element={
                  <InternalToolsWrapper>
                    {InvestmentsInputs && <InvestmentsInputs />}
                  </InternalToolsWrapper>
                } 
              />
              <Route 
                path="/internal-tools/tailwind-test" 
                element={
                  <InternalToolsWrapper>
                    <TailwindTest />
                  </InternalToolsWrapper>
                } 
              />
              <Route 
                path="/internal-tools/app-cycles" 
                element={
                  <InternalToolsWrapper>
                    {AppCycles && <AppCycles />}
                  </InternalToolsWrapper>
                } 
              />
              <Route 
                path="/internal-tools/openai-test" 
                element={
                  <InternalToolsWrapper>
                    {OpenAITest && <OpenAITest />}
                  </InternalToolsWrapper>
                } 
              />
              <Route 
                path="/internal-tools/schools" 
                element={
                  <InternalToolsWrapper>
                    {Schools && <Schools />}
                  </InternalToolsWrapper>
                } 
              />
              <Route 
                path="/applications/:id" 
                element={
                  <InternalToolsWrapper>
                    {AppSummary && <AppSummary />}
                  </InternalToolsWrapper>
                } 
              />
            </>
          )}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        {getFooter()}
      </>
    </ErrorBoundary>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <AppContent />
      </Router>
      <SpeedInsights />
      <Analytics />
    </div>
  );
}

export default App;

