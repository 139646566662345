import React from 'react';
import { Link } from 'react-router-dom';

function LightFooter() {  
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const handleHomeClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="footer">
      <div className="container">
        <hr className="light-divider"></hr>
        
        <div className="footer-links">
          {/* Bottom bar with logo and social links */}
          <div className="link-column">
            <div className="social-links">
              <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleHomeClick}>
                <h2>LEAPYEAR</h2>
              </Link>
              <div className="social-icons-container">
                <a href="https://x.com/_leapyear_" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="social-link">
                  <img 
                    src="https://img.icons8.com/?size=50&id=6Fsj3rv2DCmG&format=png&color=FFFFFF" 
                    alt="Twitter/X" 
                    className="social-icon" 
                  />
                </a>
                <a href="https://www.linkedin.com/school/leapyear" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="social-link">
                  <img 
                    src="https://img.icons8.com/?size=50&id=447&format=png&color=FFFFFF" 
                    alt="LinkedIn" 
                    className="social-icon" 
                  />
                </a>
              </div>
            </div>
          </div>

          {/* Sitemap links */}
          <div className="link-column">
            <Link to="/" onClick={handleLinkClick}>Home</Link>
            <Link to="/apply" onClick={handleLinkClick}>Apply</Link>
            <Link to="/scout" onClick={handleLinkClick}>Scout</Link>
          </div>

          <div className="link-column">
            <Link to="/investments" onClick={handleLinkClick}>Investments</Link>
            <Link to="/people" onClick={handleLinkClick}>People</Link>
            <Link to="/faq" onClick={handleLinkClick}>FAQ</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default LightFooter;







