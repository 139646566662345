import React, { useState } from 'react';
import LoadingModal from './api-loading-modal';

function LoadingModalDemo() {
  const [isOpen, setIsOpen] = useState(true);
  const [message, setMessage] = useState("1 Sec. Server is Cooking...");

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const changeMessage = () => {
    setMessage(prev => 
      prev === "This is a demo of the loading modal..." 
        ? "Loading something else..." 
        : "This is a demo of the loading modal..."
    );
  };

  return (
    <div className="container" style={{minHeight: '100vh'}}>
      <h1 className="page-title">Loading Modal Demo</h1>
      
      <div style={{marginBottom: '2rem'}}>
        <button onClick={toggleModal} style={{marginRight: '1rem'}}>
          {isOpen ? 'Hide Modal' : 'Show Modal'}
        </button>
        <button onClick={changeMessage}>
          Change Message
        </button>
      </div>

      <LoadingModal isOpen={isOpen} message={message} />
    </div>
  );
}

export default LoadingModalDemo;