import React from 'react';
import { getImageUrl } from '../../utils/supabaseStorage';

const teamData = [
  {
    name: "Hank Couture",
    title: "Founder / Partner / Helper",
    experience: [
      "VP DoorDash - US Marketplace",
      "COO Fanatics Betting & Gaming"
    ],
    image: "people/hank-couture.jpeg"
  }
];

const advisorData = [
  {
    name: "Charlton Soesanto",
    title: "Advisor - Product",
    experience: [
      "Head of Consumer Product - DoorDash",
      "Stanford"
    ],
    image: "people/charlton-soesanto.jpeg"
  },
  {
    name: "Brian Sommers",
    title: "Advisor - Sales",
    experience: [
      "1st Sales Hire DoorDash - 10 years",
      "Former Head of Sales Training"
    ],
    image: "people/brian-sommers.jpeg"
  },
  {
    name: "Drew Mitchell",
    title: "Advisor - Sales",
    experience: [
      "Global Head of Ad Sales - Uber",
      "Ex-Google. Ex-Pinterest"
    ],
    image: "people/drew-mitchell.jpeg"
  },
  {
    name: "Lionel Barrow",
    title: "Advisor - Engineering",
    experience: [
      "Head of Eng - Tegus ($900M Exit)",
      "UChicago Grad + CS Faculty"
    ],
    image: "people/lionel-barrow.jpeg"
  }
];

const builderData = [
  {
    name: "Fuad Hannon",
    title: "Early DoorDash - VP New Verticals",
    experience: [
      "Ex-Google. Ex-McKinsey",
      "Harvard + U of Minnesota"
    ],
    image: "people/fuad-hannon.jpeg"
  },
  {
    name: "Tom Elnick",
    title: "Founder & CEO - Tegus",
    experience: [
      "Sold Tegus to AlphaSense for $900M+",
      "Brown"
    ],
    image: "people/tom-elnick.jpeg"
  },
  {
    name: "Andrew Munday",
    title: "Founder & COO - Local Kitchens",
    experience: [
      "Founder & COO - Future",
      "1st Employee DoorDash"
    ],
    image: "people/andrew-munday.jpeg"
  },
  {
    name: "Ryan Letzeiser",
    title: "Founder & CEO - Obie",
    experience: [
      "YC 2019. Series B + $35M+ Raised",
      "Michigan State"
    ],
    image: "people/ryan-letzeiser.jpeg"
  },
  {
    name: "Arielle Salomon",
    title: "VP DoorDash - Customer Experience",
    experience: [
      "Ex-Bain & Company",
      "Northwestern + UPenn"
    ],
    image: "people/arielle-salomon.jpeg"
  },
  {
    name: "Sean Beausoleil",
    title: "Founder - PlaySpace (Acq. Google)",
    experience: [
      "Early Eng - Mailbox (Acq. Dropbox)",
      "Stanford"
    ],
    image: "people/sean-beausoleil.jpeg"
  },
  {
    name: "AK Barnett-Hart",
    title: "Early DoorDash - Ops / GM / Dashmart",
    experience: [
      "Ex-Goldman Sachs. Ex-Morgan Stanley",
      "Harvard"
    ],
    image: "people/ak-barnett-hart.jpeg"
  },
  {
    name: "Nolan Church",
    title: "Chief People Officer - Carta",
    experience: [
      "Head of Talent at DoorDash",
      "Founder & CEO - FairComp"
    ],
    image: "people/nolan-church.jpeg"
  },
  {
    name: "Todd Saunders",
    title: "Founder & CEO - Broadlume",
    experience: [
      "Sold Broadlume to Cyncly for $X00M+",
      "Ex-Google. Vertical SaaS Expert"
    ],
    image: "people/todd-saunders.jpeg"
  },
  {
    name: "Thomas Dowling",
    title: "Founder - TaxProper (Acq. Opendoor)",
    experience: [
      "YC 2019",
      "U of Illinois + Rhodes Scholar"
    ],
    image: "people/thomas-dowling.jpeg"
  },
  {
    name: "Ali Akhtar",
    title: "Founder - Letter AI",
    experience: [
      "YC 2023. VP - project44",
      "UChicago + Michigan"
    ],
    image: "people/ali-akhtar.jpeg"
  },
  {
    name: "Aaron Letzeiser",
    title: "Founder & COO - Obie",
    experience: [
      "YC 2019. Series B + $35M+ Raised",
      "Michigan State"
    ],
    image: "people/aaron-letzeiser.jpeg"
  },
  {
    name: "Taylor Bloom",
    title: "Founder & CTO - Foxtrot",
    experience: [
      "Series C + $150M+ Raised",
      "UT Austin"
    ],
    image: "people/taylor-bloom.jpeg"
  },
  {
    name: "Jack Ruth",
    title: "SVP, Growth & Marketplace - Rappi",
    experience: [
      "Founder of DashPass @ DoorDash",
      "Princeton"
    ],
    image: "people/jack-ruth.jpeg"
  },
  {
    name: "Thomas Stephens",
    title: "Founder & CEO - Upfront",
    experience: [
      "YC 2023. GM DoorDash",
      "Stanford"
    ],
    image: "people/thomas-stephens.jpeg"
  },
  {
    name: "CCR Reckers",
    title: "CEO - Fleetpulse",
    experience: [
      "Founder FR8Star (Acquired)",
      "VP Uber Freight. Ex-BCG."
    ],
    image: "people/ccr-reckers.jpeg"
  },
  {
    name: "Bob Casey",
    title: "CFO & Board Member - Tegus",
    experience: [
      "Investor. Ex-Student Founder",
      "Yale + Harvard"
    ],
    image: "people/bob-casey.jpeg"
  },
  {
    name: "Dylan Richter",
    title: "Early DoorDash - First 40 Employees",
    experience: [
      "Sr. Director - Sales Strategy",
      "WashU St. Louis"
    ],
    image: "people/dylan-richter.jpeg"
  },
  {
    name: "David Jesse",
    title: "Early Groupon - VP Product - 2nd PM",
    experience: [
      "CPO Fetch. Early Ebay. Ex-DoorDash",
      "Stanford + Stanford MBA"
    ],
    image: "people/david-jesse.jpeg"
  },
  {
    name: "John Keck",
    title: "Founder Macro (Sold to mmhmm)",
    experience: [
      "Head of Product at SafeBase",
      "Purdue + Harvard"
    ],
    image: "people/john-keck.jpeg"
  },
  {
    name: "Cody Aughney",
    title: "Early DoorDash - 8 Years",
    experience: [
      "VP Dasher & Logistics",
      "Bryant University"
    ],
    image: "people/cody-aughney.jpeg"
  },
  {
    name: "Ben Dearborn",
    title: "Early DoorDash - 9 Years",
    experience: [
      "Head of Strategic Finance",
      "Princeton"
    ],
    image: "people/ben-dearborn.jpeg"
  },
  {
    name: "Ryan Parietti",
    title: "Early DoorDash - 8 Years",
    experience: [
      "VP US Marketplace",
      "UPenn"
    ],
    image: "people/ryan-parietti.jpeg"
  },
  {
    name: "Ian Tallett",
    title: "Stripe - Strategic BD + Chicago Lead",
    experience: [
      "VP Business Development - ClimaCell",
      "Harvard + Harvard MBA"
    ],
    image: "people/ian-tallett.jpeg"
  },
  {
    name: "Shivani Stadvec",
    title: "VP Marketing - Tomorrow Health",
    experience: [
      "Ex-Flatiron Health ($2B Exit)",
      "U of Illinois"
    ],
    image: "people/shivani-stadvec.jpeg"
  },
  {
    name: "Steve Kenning",
    title: "VP Customer Experience - DoorDash",
    experience: [
      "Sales & Ops - ResQ. Ex-Bain",
      "Dartmouth + Western University"
    ],
    image: "people/steve-kenning.jpeg"
  },
  {
    name: "Luke Steensen",
    title: "Early Eng - Timber (Acq. Data Dog)",
    experience: [
      "Early Eng - Braintree",
      "Vanderbilt"
    ],
    image: "people/luke-steensen.jpeg"
  },
  {
    name: "Peter Quinn",
    title: "Head of BD - TruckSmarter",
    experience: [
      "Early DoorDash - First 50 Employees",
      "Sr. Director - Enterprise Sales"
    ],
    image: "people/peter-quinn.jpeg"
  },
  {
    name: "Logan Kilpatrick",
    title: "AI Product Manager - Google",
    experience: [
      "Ex-OpenAI. Ex-Apple",
      "Harvard + Northwestern"
    ],
    image: "people/logan-kilpatrick.jpeg"
  },
  {
    name: "TJ Glowienke",
    title: "Early Sales - DoorDash - 6 Years",
    experience: [
      "Head of DoorDash for Business Sales",
      "Ex-Groupon. Ex-Dutchie"
    ],
    image: "people/tj-glowienke.jpeg"
  },
  {
    name: "Caglar Oner",
    title: "VP Engineering - Eight Sleep",
    experience: [
      "Ex-Head of Payments Eng @ DoorDash",
      "2x Startup Founding Eng / CTO"
    ],
    image: "people/caglar-oner.jpeg"
  },
  {
    name: "Eric Mills",
    title: "Founder. Hacker. YC 2017",
    experience: [
      "VP MessageControl (Acq. Mimecast)",
      "Illinois"
    ],
    image: "people/eric-mills.jpeg"
  },
  {
    name: "Juan George",
    title: "SVP, Enterprise Sales - OLO",
    experience: [
      "Helped Build OLO from Seed to IPO",
      "First 10 Employees at OLO"
    ],
    image: "people/juan-george.jpeg"
  },
  {
    name: "Rob Edell",
    title: "Head of Subscription - DoorDash",
    experience: [
      "Founder & CEO - Servy (Acq. Resy)",
      "Cornell"
    ],
    image: "people/rob-edell.jpeg"
  },
  {
    name: "Andrew Lisy",
    title: "Sr. Director. Product - DoorDash",
    experience: [
      "Ex-Groupon. Ex-Trader.",
      "MIT"
    ],
    image: "people/andrew-lisy.jpeg"
  }
];

function People() {
  return (
    <div className="container">
      <h1 className="page-title">PEOPLE</h1>
      
      <div className="content-section">
        <h2>Team</h2>
        <span className="people-text grey-text">Committed and trying to help</span>
        <div className="people-container">
          {teamData.map((member, index) => (
            <div key={index} className="people-box">
              {member.image && (
                <img 
                  src={member.image.startsWith('http') ? member.image : getImageUrl(member.image)} 
                  alt={member.name} 
                  className="people-image" 
                />
              )}
              <div className="text-container">
                <div className="people-name">{member.name}</div>
                <div className="people-title">{member.title}</div>
                {member.experience.map((exp, expIndex) => (
                  <div key={expIndex} className="people-text">{exp}</div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="content-section">
        <h2>Advisors</h2>
        <span className="people-text grey-text">Function experts who know what great looks like</span>
        <div className="people-container">
          {advisorData.map((advisor, index) => (
            <div key={index} className="people-box">
              {advisor.image && (
                <img 
                  src={advisor.image.startsWith('http') ? advisor.image : getImageUrl(advisor.image)} 
                  alt={advisor.name} 
                  className="people-image" 
                />
              )}
              <div className="text-container">
                <div className="people-name">{advisor.name}</div>
                <div className="people-title">{advisor.title}</div>
                {advisor.experience.map((exp, expIndex) => (
                  <div key={expIndex} className="people-text">{exp}</div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="content-section">
        <h2>Builder Network</h2>
        <span className="people-text grey-text">Think of it like "phone-a-friend" for founders</span>
        <div className="people-container">
          {builderData.map((builder, index) => (
            <div key={index} className="people-box">
              {builder.image && (
                <img 
                  src={builder.image.startsWith('http') ? builder.image : getImageUrl(builder.image)} 
                  alt={builder.name} 
                  className="people-image" 
                />
              )}
              <div className="text-container">
                <div className="people-name">{builder.name}</div>
                <div className="people-title">{builder.title}</div>
                {builder.experience.map((exp, expIndex) => (
                  <div key={expIndex} className="people-text">{exp}</div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default People;