// Determine if we're running in production
const isProduction = process.env.NODE_ENV === 'production';

// Get the base API URL based on environment
export const getApiUrl = () => {
  // For local development
  if (!isProduction) {
    return 'http://localhost:4000'; // Hardcode the backend URL for development
  }
  
  // For production and preview deployments on Vercel
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }

  // Get the current hostname
  const hostname = window.location.hostname;

  // Check if we're on a Vercel preview URL
  if (hostname.match(/^leapyear-v2-[a-zA-Z0-9-]+-hankcoutures-projects\.vercel\.app$/)) {
    return `https://${hostname}`;
  }

  // Check if we're on the Vercel app domain
  if (hostname === 'leapyear-v2.vercel.app') {
    return 'https://leapyear-v2.vercel.app';
  }

  // Check if we're on takeleapyear.com
  if (hostname === 'www.takeleapyear.com') {
    return 'https://www.takeleapyear.com';
  }

  // Default to the official domain
  return 'https://www.leapyear.us';
};

// Helper function to build API endpoints
export const endpoints = {
  submitApplication: () => `/api/applications/submit`,
  subscribe: () => `/api/applications/subscribe`,
  submitScout: () => `/api/applications/scout`,
  testEmail: () => `/api/email/test`,
  csrfToken: () => `/api/csrf-token`,
  auth: {
    gmail: () => `/api/public/auth-gmail`,
    callback: () => `/api/public/auth-callback`
  },
  updateReview: '/api/internal/update-review',
  generateAiReview: '/api/internal/generate-ai-review'
};

// Track retry attempts
const retryAttempts = new Map();

// Helper function for making API requests
export const fetchApi = async (endpoint, options = {}) => {
  const apiUrl = getApiUrl();
  const { params, ...restOptions } = options;
  
  const cleanEndpoint = endpoint.replace(/^https?:\/\/[^/]+/, '').replace(/^\/+/, '');
  const queryString = params ? '?' + new URLSearchParams(params).toString() : '';
  const fullUrl = `${apiUrl}/${cleanEndpoint}${queryString}`;

  // For non-GET requests, ensure we have a CSRF token
  if (options.method && options.method !== 'GET') {
    try {
      console.log(`✓ Fetching CSRF token for ${options.method} ${cleanEndpoint}`);
      const tokenResponse = await fetch(`${apiUrl}/api/csrf-token`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Origin': window.location.origin
        }
      });

      if (!tokenResponse.ok) {
        console.log(`✗ Failed to fetch CSRF token for ${options.method} ${cleanEndpoint}`);
        throw new Error(`Failed to fetch CSRF token: ${tokenResponse.statusText}`);
      }

      await tokenResponse.json();
      console.log(`✓ CSRF token received for ${options.method} ${cleanEndpoint}`);

      // Wait for the token to be set in cookies
      await new Promise(resolve => setTimeout(resolve, 100));
    } catch (error) {
      console.log(`✗ CSRF setup failed for ${options.method} ${cleanEndpoint}: ${error.message}`);
      throw error;
    }
  }

  // Get CSRF token from cookie
  const csrfToken = document.cookie
    .split('; ')
    .find(row => row.startsWith('XSRF-TOKEN'))
    ?.split('=')[1];

  if (!csrfToken && options.method && options.method !== 'GET') {
    console.log(`✗ No CSRF token found for ${options.method} ${cleanEndpoint}`);
    throw new Error('No CSRF token available');
  }

  // Add headers including CSRF token if available
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Origin': window.location.origin,
    ...(csrfToken && { 'X-CSRF-Token': decodeURIComponent(csrfToken) }),
    ...options.headers,
  };

  try {
    const response = await fetch(fullUrl, {
      ...restOptions,
      credentials: 'include',
      headers,
    });

    if (!response.ok) {
      const error = await response.json().catch(() => ({}));
      if (response.status === 403 && error.error === 'Invalid CSRF token') {
        const currentAttempts = retryAttempts.get(fullUrl) || 0;
        if (currentAttempts >= 3) {
          retryAttempts.delete(fullUrl);
          console.log(`✗ Max CSRF retries reached for ${options.method} ${cleanEndpoint}`);
          throw new Error('Maximum retry attempts reached for CSRF token');
        }
        
        retryAttempts.set(fullUrl, currentAttempts + 1);
        console.log(`⟳ Retrying with new CSRF token for ${options.method} ${cleanEndpoint} (${currentAttempts + 1}/3)`);
        
        document.cookie = 'XSRF-TOKEN=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        
        return fetchApi(endpoint, options);
      }
      console.log(`✗ ${options.method} ${cleanEndpoint} failed: ${error.message || response.statusText}`);
      throw new Error(error.message || error.error || `API request failed: ${response.status} ${response.statusText}`);
    }

    console.log(`✓ ${options.method} ${cleanEndpoint} succeeded`);
    retryAttempts.delete(fullUrl);
    return response.json();
  } catch (error) {
    console.log(`✗ ${options.method} ${cleanEndpoint} error: ${error.message}`);
    throw error;
  }
};

export const getFrontendURL = () => {
  if (typeof window === 'undefined') return process.env.FRONTEND_URL_PUBLIC;
  
  const hostname = window.location.hostname;
  if (hostname.includes('vercel.app')) {
    return process.env.FRONTEND_URL_VERCEL;
  }
  return process.env.FRONTEND_URL_PUBLIC;
}; 